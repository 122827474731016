import {GeneralActions} from '@/store/general/actions';
import {AnyAction} from 'redux';
import {actionOf, GenericEpic, pipe} from '@/store/epic-helper';
import {Observable} from 'rxjs';

const loadTimeSlotsEpic: GenericEpic = (action$, _state$, {timeSlotsApi}): Observable<AnyAction> => {
    return pipe(
        action$,
        actionOf(GeneralActions.loadTimeSlots),
        () => timeSlotsApi.get(),
        response => GeneralActions.loadTimeSlotsSuccess(response),
        (action, error) => GeneralActions.loadTimeSlotsFailed({
            action: action,
            axios: error,
            message: 'Loading timeslots failed',
            description: 'A problem occurred while fetching timeslots',
            hasBeenShown: false
        }));
};

export default [
    loadTimeSlotsEpic
];