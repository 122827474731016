import { State } from './state';
import { initValue, loadingValue, loadedValue, errorValue } from '@/store/retrieved-value';
import { Actions, GeneralActions } from './actions';
import { isActionFromContainer } from '@/store/action';

const initialState: State = {
    timeSlots: initValue(),
};

export const reducer = (state = initialState, action: Actions): State => {
    if (!isActionFromContainer(action, GeneralActions)) {
        return state;
    }

    switch (action.action) {

        case 'loadTimeSlots':
            return { ...state, timeSlots: loadingValue(state.timeSlots.value) };
        case 'loadTimeSlotsSuccess':
            return { ...state, timeSlots: loadedValue(action.payload.timeSlots) };
        case 'loadTimeSlotsFailed':
            return { ...state, timeSlots: errorValue(action.payload.error) };

        default:
            return state;
    }
};