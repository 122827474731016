import React from 'react';
import { Button as AntButton } from 'antd';
import { useTranslate } from '@/language-provider';
import styled from 'styled-components';
import { ExtractProps } from '@/react/type-helpers';

export interface ButtonProps extends ExtractProps<typeof AntButton> {
    translation?: string;
    tooltipTranslation?: string;
    text?: string;
}

export function Button(props: ButtonProps): React.ReactElement {

    const { translate } = useTranslate();

    let translatedTooltip = undefined;
    if (props.tooltipTranslation) {
        translatedTooltip = translate(props.tooltipTranslation).toString();
    }

    let translatedText = undefined;
    if (props.text || props.translation) {
        translatedText = props.text ?? translate(props.translation).toString();
    }

    if (props.icon != null && props.translation != null) {
        translatedText = <Wrapper>{translatedText}</Wrapper>;
    }

    return (
        <AntButton {...props} title={translatedTooltip}>
            {translatedText}
        </AntButton>
    );
}

const Wrapper = styled.p`
    display: inline;
    padding-left: 5px;
`;