import moment from 'moment';
import {ApplicationData, Mode} from './application-data';
import { Validator } from './validator';
import { Country } from '@/client/models';

export class WelcomeValidator implements Validator {
    private countries: Country[];

    constructor() {
        this.initialize = this.initialize.bind(this);
        this.validate = this.validate.bind(this);
    }
    
    public validate(data: ApplicationData): boolean {
        if (data.child.dateOfBirth == null || data.startAt == null) {
            return false;
        }
        if (data.child.dateOfBirth.isBefore(moment.now()) && data.child.firstName == null) {
            return false;
        }
        if (data.mode === Mode.new && (data.zipCode == null || !this.isZipCodeValid(data.country, data.zipCode))) {
            return false;
        }
        return data.child.dateOfBirth.isBefore(data.startAt);
    }

    private isZipCodeValid(country: string, zipCode: string): boolean {
        const pattern = this.countries?.find(x => x.countryId === country)?.postcodePattern;
        if (pattern == null || pattern === '') {
            return true;
        }
        const rx = new RegExp(pattern, 'g');
        return rx.test(zipCode);
    }

    public initialize(countries: Country[]): void {
        this.countries = countries;
    }
}
