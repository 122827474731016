import { Address} from './address';
import { Gender } from './gender';
import moment from 'moment';

export interface PrimaryContactDetails {
    firstName?: string;
    initials?: string;
    lastName?: string;
    dateOfBirth?: moment.Moment;
    gender?: Gender;
    phoneNumber?: string;
    address?: Address;
    email: string;
}

export function onDeserializePrimaryContactDetails(data: PrimaryContactDetails): PrimaryContactDetails {
    if (data == null) {
        return data;
    }

    if (data.dateOfBirth != null) {
        data.dateOfBirth = moment(data.dateOfBirth);
    }

    return data;
}

export function onSerializePrimaryContactDetails(data: PrimaryContactDetails): PrimaryContactDetails {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.dateOfBirth = data.dateOfBirth?.format('YYYY-MM-DD') as unknown as moment.Moment;

    return serialized;
}
