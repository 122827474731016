import { WizardPart } from './wizard-part';
import { ProgressReporter } from './progress-reporter';
import { CareTypeReference } from '@/client/models';

export interface StepData {
    careType?: CareTypeReference;
}

export class CareTypeStepProvider {
    private data: StepData;
    private reporter: ProgressReporter;
    private careTypes: CareTypeReference[];

    constructor(data: StepData, reporter: ProgressReporter) {
        this.data = data;
        this.reporter = reporter;
        this.initialize = this.initialize.bind(this);
        this.getCareTypes = this.getCareTypes.bind(this);
        this.autoSelectCareType = this.autoSelectCareType.bind(this);
        this.isCareTypeSelected = this.isCareTypeSelected.bind(this);
        this.toggleCareType = this.toggleCareType.bind(this);
        this.requiresSchool = this.requiresSchool.bind(this);
    }

    public initialize(careTypes: CareTypeReference[]): void {
        this.careTypes = careTypes;
    }

    public getCareTypes(): CareTypeReference[] {
        return this.careTypes;
    }

    public autoSelectCareType(ageAtStart: number): boolean {
        const careTypes = this.getCareTypes();
        const possible = new Array<CareTypeReference>();
        
        for (const careType of careTypes) {
            if (careType.fromAge <= ageAtStart
                && (careType.toAge == null || careType.toAge > ageAtStart)) {
                possible.push(careType);
            }
        }

        if (possible.length === 1) {
            this.setCareType(possible[0]);
            return true;
        }
        return false;
    }

    public isCareTypeSelected(careType: CareTypeReference): boolean {
        return this.data.careType?.careType === careType?.careType;
    }

    public requiresSchool(): boolean {
        return this.data.careType?.requiresSchool;
    }

    public toggleCareType(careType: CareTypeReference): void {
        if (this.isCareTypeSelected(careType)) {
            this.setCareType(undefined);
        } else {
            this.setCareType(careType);
        }
    }

    private setCareType(careType: CareTypeReference): void {
        this.data.careType = careType;
        this.reporter.partChanged(WizardPart.careType);
    }
}