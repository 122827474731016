import { LocationPackage } from './location-package';
import { ProgressReporter } from './progress-reporter';
import { CarePackage, Location } from '@/client/models';
import { WizardPart } from './wizard-part';

export interface StepData {
    packages?: LocationPackage[];
}

export interface LocationStepCallback {
    carePackageSelected(carePackage: LocationPackage): void;
    carePackageDeselected(carePackage: LocationPackage): void;
}

export class LocationStepProvider {
    private data: StepData;
    private reporter: ProgressReporter;
    private callback: LocationStepCallback;

    constructor(data: StepData, reporter: ProgressReporter, callback: LocationStepCallback) {
        this.data = data;
        this.reporter = reporter;
        this.callback = callback;

        this.initialize = this.initialize.bind(this);
        this.isLocationSelected = this.isLocationSelected.bind(this);
        this.isLocationPackageSelected = this.isLocationPackageSelected.bind(this);
        this.toggleLocationPackage = this.toggleLocationPackage.bind(this);
    }

    public initialize(): void { 
        if (this.data.packages == null) {
            this.data.packages = new Array<LocationPackage>();
        }
    }

    public isLocationSelected(locationId: string): boolean {
        return this.data.packages.some(x => x.locationId === locationId);
    }

    public isLocationPackageSelected(locationId: string, carePackageId: string): boolean {
        return this.data.packages.some(x => x.locationId === locationId && x.carePackageId === carePackageId);
    }

    public findLocationPackage(locationId: string, carePackageId: string): { index: number; carePackage: LocationPackage } {
        const index = this.data.packages.findIndex(x => x.locationId === locationId && x.carePackageId === carePackageId);
        const carePackage = index >= 0 ? this.data.packages[index] : undefined;
        return { index, carePackage };
    }

    public toggleLocationPackage(location: Location, carePackage: CarePackage): void {
        const { index } = this.findLocationPackage(location.locationId, carePackage.carePackageId);
        if (index >= 0) {
            const removed = this.data.packages.splice(index, 1);
            this.callback.carePackageDeselected(removed[0]);
        } else {
            const locationPackage: LocationPackage = { locationId: location.locationId, locationName: location.name, carePackageId: carePackage.carePackageId, carePackagePakketLabelId: carePackage.carePackagePakketLabelId, carePackageName: carePackage.name, timeslots: [] };
            this.callback.carePackageSelected(locationPackage);
            this.data.packages.push(locationPackage);
        }
        this.reporter.partChanged(WizardPart.locations);
    }
}
