import { User } from '../models/user';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class UsersApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getMe(headers?: AxiosHeaders): Promise<AxiosResponse<User>> {
        const request = {
            url: this.baseUrl + '/users/me',
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<User>(request);
        

        return response;
    }
}