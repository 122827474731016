import { GenericEpic, actionOf, pipe } from '@/store/epic-helper';
import { ApplicationActions } from './actions';

const loadOptionsEpic: GenericEpic = (action$, _state$, { optionsApi }) =>
    pipe(
        action$,
        actionOf(ApplicationActions.loadRegistrationOptions),
        () => optionsApi.get(),
        response => ApplicationActions.loadRegistrationOptionsSuccess(response),
        (action, error) => ApplicationActions.loadRegistrationOptionsFailed({
            action,
            axios: error,
            message: 'Loading options has failed',
            description: 'A problem occurred while trying to retrieve the options from the server',
            hasBeenShown: false
        }));

const loadQuestionsEpic: GenericEpic = (action$, _state$, { questionsApi }) =>
    pipe(
        action$,
        actionOf(ApplicationActions.loadQuestions),
        () => questionsApi.get(),
        response => ApplicationActions.loadQuestionsSuccess(response),
        (action, error) => ApplicationActions.loadQuestionsFailed({
            action,
            axios: error,
            message: 'Loading questions has failed',
            description: 'A problem occurred while trying to retrieve the questions from the server',
            hasBeenShown: false
        }));

const loadContentEpic: GenericEpic = (action$, _state$, { contentApi }) =>
    pipe(
        action$,
        actionOf(ApplicationActions.loadContent),
        ({ payload: { languageCode } }) => contentApi.getAll(languageCode, { ['Accept-Language']: languageCode }),
        response => ApplicationActions.loadContentSuccess(response),
        (action, error) => ApplicationActions.loadContentFailed({
            action,
            axios: error,
            message: 'Loading questions has failed',
            description: 'A problem occurred while trying to retrieve the content from the server',
            hasBeenShown: false
        }));


const loadLocationsEpic: GenericEpic = (action$, _state$, { locationsApi }) =>
    pipe(
        action$,
        actionOf(ApplicationActions.loadLocations),
        ({ payload: { date, schoolId, careType } }) => locationsApi.get(date, schoolId, careType),
        response => ApplicationActions.loadLocationsSuccess(response),
        (action, error) => ApplicationActions.loadLocationsFailed({
            action,
            axios: error,
            message: 'Loading locations has failed',
            description: 'A problem occurred while trying to retrieve the locations from the server',
            hasBeenShown: false
        }));

const loadCarePackageEpic: GenericEpic = (action$, _state$, { locationsApi }) =>
    pipe(
        action$,
        actionOf(ApplicationActions.loadCarePackageDetails),
        ({ payload: { carePackageId, locationId, date, schoolId } }) => locationsApi.getLocationIdCarepackagesCarePackageId(locationId, carePackageId, date, schoolId),
        response => ApplicationActions.loadCarePackageDetailsSuccess(response),
        (action, error) => ApplicationActions.loadCarePackageDetailsFailed({
            action,
            axios: error,
            message: 'Loading care package details has failed',
            description: 'A problem occurred while trying to retrieve the care package details from the server',
            hasBeenShown: false
        }));

const postNewApplicationEpic: GenericEpic = (action$, _state$, { applicationsApi }) =>
    pipe(
        action$,
        actionOf(ApplicationActions.postNewApplication),
        ({ payload: { command } }) => applicationsApi.post(command),
        response => ApplicationActions.postNewApplicationSuccess(response),
        (action, error) => {
            let description = 'A problem occurred while trying to save the new application to the server';
            if (error?.response?.status === 400 && error?.response?.data != null) {
                const data = error.response.data;
                if (data.errors != null) {
                    const errors = error.response.data.errors;
                    description = Object.keys(errors).flatMap(x => errors[x]).join('<br />');
                }
                if (data.message != null) {
                    description = data.message;
                }
            }

            return ApplicationActions.postNewApplicationFailed({
                action,
                axios: error,
                message: 'Saving new application has failed',
                description: description,
                hasBeenShown: false
            });
        });

const postAddChildEpic: GenericEpic = (action$, _state$, { applicationsApi }) =>
    pipe(
        action$,
        actionOf(ApplicationActions.postAddChild),
        ({ payload: { command } }) => applicationsApi.postAddChild(command),
        () => ApplicationActions.postAddChildSuccess(),
        (action, error) => {
            let description = 'A problem occurred while trying to save the application to the server';
            if (error?.response?.status === 400 && error?.response?.data != null) {
                const data = error.response.data;
                if (data.errors != null) {
                    const errors = error.response.data.errors;
                    description = Object.keys(errors).flatMap(x => errors[x]).join('<br />');
                }
                if (data.message != null) {
                    description = data.message;
                }
            }

            return ApplicationActions.postAddChildFailed({
                action,
                axios: error,
                message: 'Saving new application has failed',
                description: description,
                hasBeenShown: false
            });
        });

export default [
    loadCarePackageEpic,
    loadOptionsEpic,
    loadLocationsEpic,
    loadQuestionsEpic,
    loadContentEpic,
    postNewApplicationEpic,
    postAddChildEpic,
];