import React, { useState } from 'react';
import { Layout } from 'antd';
import { initializeFontAwesome } from './font-awesome';

export const AppComponent: React.FC = ({ children }) => {

    useState(() => {
        initializeFontAwesome();
    });

    return (
        <Layout style={{ height: '100vh' }}>
            <Layout>
                <Layout style={{ background: '#fff' }}>
                    <Layout.Content style={{ height: '100%' }}>
                        {children}
                    </Layout.Content>
                </Layout>
            </Layout>
        </Layout>
    );
};