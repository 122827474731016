/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { TimeSlotsResponse } from '@/client/models';
import { GenericError } from '@/store/generic-error';
import { ActionsUnion } from '@/store/types';
import { action } from '@/store/decorators';
import { Action } from '@/store/action';

export class GeneralActions {
    public static __className = 'GeneralActions';

    @action
    static resetApp() { return Action('resetApp'); }

    @action
    static loadTimeSlots() { return Action('loadTimeSlots'); }
    @action
    static loadTimeSlotsSuccess(response: TimeSlotsResponse) { return Action('loadTimeSlotsSuccess', { ...response }); }
    @action
    static loadTimeSlotsFailed(error: GenericError<typeof GeneralActions.loadTimeSlots>) { return Action('loadTimeSlotsFailed', { error }); }
}

export type Actions = ActionsUnion<typeof GeneralActions>;