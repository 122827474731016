import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRootReducer } from '@/store/reducers';
import { createEpicMiddleware } from 'redux-observable';
import { epics } from '@/store/epics';
import { createDependencies } from '@/store/dependencies';
import LogRocket from 'logrocket';

const epicMiddleware = createEpicMiddleware({
    dependencies: createDependencies()
});

const composeEnhancers = composeWithDevTools({ trace: true });

export const store = createStore(
    createRootReducer(),
    undefined,
    composeEnhancers(
        applyMiddleware(
            epicMiddleware,
            LogRocket.reduxMiddleware()
        )
    )
);

epicMiddleware.run(epics);