import { NetworkUtils } from './networking/network-utils';

export enum EnvironmentMode {
    development = 'development',
    production = 'production'
}

export interface Environment {
    environment: EnvironmentMode;
    buildnumber: string;
    commit: string;
    apiUrl: string;
    backendUrl?: string;
    tenant?: string;
}

const environments: { [key: string]: Environment } = {
    development: {
        environment: EnvironmentMode.development,
        buildnumber: env.buildnumber,
        commit: env.commit,
        /*apiUrl: 'https://localhost:8001',
        backendUrl: 'http://localhost:5000/'*/
        //apiUrl: 'https://inschrijven.kindplanner.nl/api'
        backendUrl: 'http://localhost:5000',
        apiUrl: 'https://kp.eu.ngrok.io'
    },
    production: {
        environment: EnvironmentMode.production,
        buildnumber: env.buildnumber,
        commit: env.commit,
        apiUrl: window.location.origin + '/api'
    }
};

export function getEnvironment(): Environment {
    const tenant = NetworkUtils.getTenantFromUrl();

    if (env.mode === EnvironmentMode.development || NetworkUtils.isDevelop()) {
        return { ...environments.development, tenant };
    }

    return { ...environments.production, tenant };
}