import { ApplicationData } from './application-data';
import {NewApplicationCommand, Address, ContactDetails, ApplicationAnswer, PrimaryContactDetails, AddChildCommand, Package, Child, ChildDetails} from '@/client/models';
import { PersonData, ContactInfoData } from './person-data';

export class ApplicationDataConverter {

    constructor() {
        this.toNewCommand = this.toNewCommand.bind(this);
    }

    public toNewCommand(data: ApplicationData): NewApplicationCommand {
        const address = ApplicationDataConverter.getAddress(data);

        return {
            applicationId: data.applicationId,
            child: ApplicationDataConverter.getChildData(data.child),
            careType: data.careType?.careType,
            primaryContact: ApplicationDataConverter.getPersonData(data.primaryContact, address) as PrimaryContactDetails,
            partner: ApplicationDataConverter.getPersonData(data.partner, address),
            startAt: data.startAt,
            schoolId: data.school?.schoolId,
            preferredLocations: data.packages.map(x => x.locationId),
            packages: ApplicationDataConverter.getCarePackages(data),
            answers: ApplicationDataConverter.getAnswersWithoutRemark(ApplicationDataConverter.getAnswers(data)),
            remarks: ApplicationDataConverter.getRemark(data)
         };
    }

    private static getRemark(data: ApplicationData): string {
        for (const answer of data.answers) {
            if (answer.questionId === 'remark') {
                return answer.answer;
            }
        }
        return '';
    }

    private static getAnswersWithoutRemark(answers: ApplicationAnswer[] ): ApplicationAnswer[] {
        const result = new Array<ApplicationAnswer>();

        for (const answer of answers) {
            if (answer.questionId === 'remark') {
                continue;
            }
            result.push(answer);
        }
        return result;
    }

    public toAddCommand(data: ApplicationData): AddChildCommand {
        return {
            applicationId: data.existingApplicationId,
            child: ApplicationDataConverter.getChildData(data.child),
            careType: data.careType?.careType,
            startAt: data.startAt,
            schoolId: data.school?.schoolId,
            preferredLocations: data.packages.map(x => x.locationId),
            packages: ApplicationDataConverter.getCarePackages(data),
            answers: ApplicationDataConverter.getAnswersWithoutRemark(ApplicationDataConverter.getAnswers(data)),
            remarks: ApplicationDataConverter.getRemark(data)
        };
    }

    private static getChildData(child: Child): ChildDetails {
        let phoneNumber = '+' + child.emergencyNumber.code;
        if (child.emergencyNumber.phone.startsWith('0')) {
            phoneNumber += child.emergencyNumber.phone.substring(1);
        } else {
            phoneNumber += child.emergencyNumber.phone;
        }
        return {
            firstName: child.firstName,
            middleName: child.middleName,
            lastName: child.lastName,
            gender: child.gender,
            dateOfBirth: child.dateOfBirth,
            emergencyNumber: phoneNumber
        };
    }

    private static getPersonData(person: PersonData & ContactInfoData, address: Address): ContactDetails {
        if (person?.firstName == null) {
            return undefined;
        }
        let phoneNumber = undefined;
        if (person.phone.phone) {
            phoneNumber = '+' + person.phone.code;
            if (person.phone.phone.startsWith('0')) {
                phoneNumber += person.phone.phone.substring(1);
            } else {
                phoneNumber += person.phone.phone;
            }
        }
        
        return {
            email: person.email,
            phoneNumber: phoneNumber,
            lastName: person.lastName,
            middleName: person.middleName,
            firstName: person.firstName,
            initials: person.initials,
            dateOfBirth: person.dateOfBirth,
            gender: person.gender,
            address: address
        };
    }

    private static getAddress(data: ApplicationData): Address {
        return {
            street: data.street,
            number: data.number,
            extension: data.numberExtension,
            zipCode: data.zipCode,
            city: data.city,
            municipalityId: data.municipality,
            countryId: data.country,
            type: 0
        };
    }

    private static getCarePackages(data: ApplicationData): Package[] {
        const result = new Array<Package>();
        for (const locationPackage of data.packages) {
            result.push({
                id: locationPackage.carePackageId,
                timeSlots: locationPackage.timeslots.map(x => x.timeslotId)
            });
        }
        return result;
    }

    private static getAnswers(data: ApplicationData): ApplicationAnswer[] {
        const result = new Array<ApplicationAnswer>();
        for (const answer of data.answers) {
            if (answer.answer == null || answer.answer === '') {
                continue;
            }
            result.push({
                answer: answer.answer,
                questionId: answer.questionId,
            });
        }
        return result;
    }
}