import styled from 'styled-components';
import * as R from 'ramda';


export const GridLayoutItem = styled.div<{ column?: number; row?: number; columnSpan?: number; rowSpan?: number }>`
    grid-column-start: ${props => props.column == null ? 1 : props.column};
    grid-column-end: ${props => (props.column == null ? 1 : props.column) + (props.columnSpan == null ? 1 : props.columnSpan)};
    grid-row-start: ${props => props.row == null ? 1 : props.row};
    grid-row-end: ${props => (props.row == null ? 1 : props.row) + (props.rowSpan == null ? 1 : props.rowSpan)};
`;

export type GridColRowSpec = { count: number; size: string };
export const GridLayout = styled.div<{ columns: number | GridColRowSpec; rows: number | GridColRowSpec; rowgap?: string; colgap?: string }>`
    display: grid;

    grid-row-gap: ${props => props.rowgap ?? '0px'};
    grid-column-gap: ${props => props.colgap ?? '0px'};
    grid-template-columns: ${props => {
        const count = typeof props.columns === 'number' ? props.columns : props.columns.count;
        const size = typeof props.columns === 'number' ? '1fr': props.columns.size;

        return R.join(' ', R.map(() => size, R.range(0, count)));
    }};

    grid-template-rows: ${props => {
        const count = typeof props.rows === 'number' ? props.rows : props.rows.count;
        const size = typeof props.rows === 'number' ? '1fr': props.rows.size;

        return R.join(' ', R.map(() => size, R.range(0, count)));
    }};
`;