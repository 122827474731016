import { ApplicationData } from './application-data';
import { Validator } from './validator';

export class CarePackageValidator implements Validator {

    private locationId: string;
    private carePackageId: string;

    constructor() {
        this.initialize = this.initialize.bind(this);
    }

    public initialize(locationId: string, carePackageId: string): void {
        this.locationId = locationId;
        this.carePackageId = carePackageId;
    }

    public validate(data: ApplicationData): boolean {
        return !!data.packages?.some(x => x.locationId === this.locationId 
            && x.carePackageId === this.carePackageId 
            && !!x.timeslots?.length);
    }
}