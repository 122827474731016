import {ApplicationState} from '@/store/application-state';
import {createSelector} from 'reselect';
import {Status} from '@/store/status';

export const applicationSelector = createSelector((state: ApplicationState) => state, x => x.application);

export const registrationOptionsSelector = createSelector(applicationSelector, x => x.options?.value);
export const registrationOptionsRetrievedSelector = createSelector(applicationSelector, x => x.options);

export const countriesSelector = createSelector(registrationOptionsSelector, x => x?.countries);

export const questionsRetrievedSelector = createSelector(applicationSelector, x => x.questions);

export const locationsSelector = createSelector(applicationSelector, x => x.locations?.value);
export const locationsRetrievedSelector = createSelector(applicationSelector, x => x.locations);

export const contentRetrievedSelector = createSelector(applicationSelector, x => x.content);

export const carePackageDetailsRetrievedSelector = createSelector(applicationSelector, x => x.carePackageDetails);

export const newApplicationRetrievedSelector = createSelector(applicationSelector, x => x.application);
export const addChildRetrievedSelector = createSelector(applicationSelector, x => x.addChild);

export const submitFormSelector = createSelector([newApplicationRetrievedSelector, addChildRetrievedSelector], (a, b) => {
    if (a.status === Status.Loading || b.status === Status.Loading) {
        return { status: Status.Loading };
    }
    
    if (a.status === Status.Error || b.status === Status.Error) {
        return { status: Status.Error, error: a.error ?? b.error };
    }
    
    if (a.status === Status.Loaded || b.status === Status.Loaded) {
        return { status: Status.Loaded };
    }
    
    return { status: Status.Init };
});