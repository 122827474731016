import moment from 'moment';
import { WizardPart } from './wizard-part';
import { ProgressReporter } from './progress-reporter';
import { Child, Country, Municipality } from '@/client/models';
import { StepData as YourDataStepData } from './your-data-step-provider';
import LogRocket from 'logrocket';
import { NetworkUtils } from '@/networking/network-utils';

export interface StepData {
    child?: Child;
    startAt?: moment.Moment;
    country?: string;
    municipality?: string;
    zipCode?: string;
    number?: string;
    street?: string;
    city?: string;
}

export class WelcomeStepProvider {
    public data: StepData;
    private reporter: ProgressReporter;
    private countries: Country[];
    private municipalities: Municipality[];

    constructor(data: StepData, reporter: ProgressReporter) {
        this.data = data;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        /*const some: any = data;
        some._country = data.country;

        // overwrite with accessor
        Object.defineProperty(some, 'country', {
            get: function () {
                return some._country;
            },

            set: function (value) {
                // eslint-disable-next-line no-debugger
                debugger; // sets breakpoint
                some._country = value;
            }
        });*/

        this.reporter = reporter;
        this.initialize = this.initialize.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.getSelectedCountry = this.getSelectedCountry.bind(this);
        this.getMunicipalities = this.getMunicipalities.bind(this);
        this.getSelectedMunicipality = this.getSelectedMunicipality.bind(this);
        this.getFormData = this.getFormData.bind(this);
        this.setFormData = this.setFormData.bind(this);
        this.isFirstNameRequired = this.isFirstNameRequired.bind(this);
        this.setDateOfBirth = this.setDateOfBirth.bind(this);
        this.setStartAt = this.setStartAt.bind(this);
        this.setCountry = this.setCountry.bind(this);
        this.setMunicipality = this.setMunicipality.bind(this);
        this.next = this.next.bind(this);
    }

    public initialize(countries: Country[], municipalities: Municipality[]): void {
        this.countries = countries;
        this.municipalities = municipalities;
        if (this.data.child == null) {
            this.data.child = { gender: undefined, dateOfBirth: undefined };
        }

        this.changed();
    }

    public getCountries(): Country[] {
        return this.countries;
    }

    public getSelectedCountry(): Country {
        return this.countries?.find(x => x.countryId === this.data.country);
    }

    public getMunicipalities(): Municipality[] {
        return this.municipalities;
    }

    public getSelectedMunicipality(): Municipality {
        return this.municipalities?.find(x => x.municipalityId === this.data.municipality);
    }

    public getFormData(): StepData {
        return { ...this.data };
    }

    public setFormData(data: StepData): void {
        if (data.country != null) {
            this.data.country = data.country;
        }

        if (data.municipality != null) {
            this.data.municipality = data.municipality;
        }

        this.data.child.dateOfBirth = data.child.dateOfBirth?.startOf('day');
        this.data.child.firstName = data.child.firstName;
        this.data.startAt = data.startAt?.startOf('day');
        this.data.zipCode = data.zipCode;
        this.data.number = data.number;
        this.changed();
    }

    public setCountry(country: string): void {
        this.data.country = country;
        this.changed();
    }

    public setMunicipality(municipality: string): void {
        this.data.municipality = municipality;
        this.changed();
    }

    public setDateOfBirth(date: moment.Moment): void {
        this.data.child.dateOfBirth = date?.startOf('day');
        this.changed();
    }

    public setStartAt(date: moment.Moment): void {
        this.data.startAt = date?.startOf('day');
        this.changed();
    }

    public isFirstNameRequired(): boolean {
        if (this.data.child.dateOfBirth == null) {
            return false;
        }

        return this.data.child.dateOfBirth.isBefore(moment.now());
    }

    public setAddress(city: string, street: string, zipCode?: string): void {
        const addressData = this.data as YourDataStepData;

        if (zipCode) {
            addressData.zipCode = zipCode;
        }

        if (addressData.city === city && addressData.street === street) {
            return;
        }

        addressData.city = city;
        addressData.street = street;
        this.changed();
    }

    public next(): void {

        LogRocket.identify(`${this.data.child.firstName}:${this.data.zipCode}:${this.data.child.dateOfBirth.format('yyyy-MM-DD')}`, {
            child: this.data.child.firstName,
            dateOfBirth: this.data.child.dateOfBirth.format('yyyy-MM-DD'),
            postalCode: this.data.zipCode,
            tenant: NetworkUtils.getTenantFromUrl()
        });

        this.reporter.partChanged(WizardPart.welcome);
    }

    private changed(): void {
        this.reporter.partChanged(WizardPart.welcome, false);
    }
}
