import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useApplicationProvider, ApplicationProvider } from '@/applications/application-provider';
import { HomeOutlined } from '@ant-design/icons';
import { Location, CareType, CarePackage } from '@/client/models';
import { InfoCard } from '@/components/info-card';
import { LoadingContainer } from '@/components/loading-container';
import { locationsRetrievedSelector } from '@/store/application/selectors';
import { Status } from '@/store/status';
import { GridLayout, GridLayoutItem } from '@/components/grid-layout';
import { useTranslate } from '@/language-provider';
import { Divider } from 'antd';

const LocationComponent: React.FC<{ location: Location }> = ({ location }) => {
    const applicationProvider = useApplicationProvider();
    const provider = applicationProvider.getLocationStepProvider();
    const [showPackages, setShowPackages] = useState(provider.isLocationSelected(location.locationId));

    function addressLine1(location: Location): string {
        const a = location.address;
        return `${a.street} ${a.number ?? ''}${a.extension ?? ''}`;
    }
    function addressLine2(location: Location): string {
        const a = location.address;
        return `${a.zipCode} ${a.city}`;
    }

    const columns = 1;
    const rows = location.carePackages.length / columns;

    return (
        <div>
            <InfoCard
                icon={<HomeOutlined />}
                imageUrl={location.imageUrl}
                title={location.name}
                lines={[addressLine1(location), addressLine2(location)]}
                onClick={() => setShowPackages(!showPackages)} />

            {showPackages &&
                <div style={{paddingLeft: '32px'}}>
                    <GridLayout columns={columns} rows={rows} rowgap="8px" colgap="8px">
                        {location.carePackages.map((x, i) => (
                            <GridLayoutItem key={x.carePackageId} column={i / rows + 1} row={i % rows + 1}>
                                <CarePackageComponent location={location} carePackage={x} applicationProvider={applicationProvider} />
                            </GridLayoutItem>
                        ))}
                    </GridLayout>
                    <Divider />
                </div>
            }
        </div>
    );
};

const CarePackageComponent: React.FC<{ location: Location; carePackage: CarePackage; applicationProvider: ApplicationProvider }> = ({ location, carePackage, applicationProvider }) => {
    const { translate } = useTranslate();
    const provider = applicationProvider.getLocationStepProvider();
    const [isSelected, setIsSelected] = useState(provider.isLocationPackageSelected(location.locationId, carePackage.carePackageId));

    applicationProvider.onChanged(() => setIsSelected(provider.isLocationPackageSelected(location.locationId, carePackage.carePackageId)));

    function line1(): string {
        return `${translate(`global.careType.${carePackage.careType}`)} ${carePackage.numberOfWeeks} weken`;
    }

    function code(): string {
        if (carePackage.careType === CareType.Daycare) {
            return 'KDV';
        } else if (carePackage.careType === CareType.Playschool) {
            return 'POP';
        } else if (carePackage.careType === CareType.BetweenSchoolCare) {
            return 'TSO';
        } else if (carePackage.careType === CareType.HolidayCare) {
            return 'VAK';
        }
        return 'BSO';
    }
    return (
        <InfoCard code={code()} title={carePackage.name} lines={[line1()]} isSelected={isSelected} onClick={() => provider.toggleLocationPackage(location, carePackage)} />
    );
};

export const LocationsStep: React.FC = () => {
    const retrieved = useSelector(locationsRetrievedSelector);

    if (retrieved.status !== Status.Loaded) {
        return <LoadingContainer />;
    }

    return (<>{retrieved.value.map(x => <LocationComponent key={x.locationId} location={x} />)}</>);
};