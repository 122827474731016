import React, {useState} from 'react';
import {useApplicationChanged, useApplicationProvider, useMode, useOnNext, useYourDataStepProvider} from '@/applications/application-provider';
import {GenderSelection} from '@/applications/your-data-step-provider';
import {Form, Input, Radio} from 'antd';
import {DateInput, FormItem, FormUtilities} from '../components/form-item';
import {RadioGroupProps} from 'antd/lib/radio';
import {country, useTranslate} from '@/language-provider';
import {useForm} from 'antd/lib/form/Form';
import Title from 'antd/lib/typography/Title';
import {ToText} from '@/applications/steps-provider';
import styled from 'styled-components';
import {Mode} from '@/applications/application-data';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import nl from 'world_countries_lists/data/nl/world.json';
import { PhoneData } from '@/applications/person-data';
export interface Country {
    id: number,
    name: string,
    alpha2: string,
    alpha3: string
}

export const YourDataStep: React.FC = () => {
    const provider = useYourDataStepProvider();
    const applicationProvider = useApplicationProvider();
    const selectedCountry = applicationProvider.getWelcomeStepProvider().getSelectedCountry();
    const [form] = useForm();
    const [formData, setFormData] = useState(provider.getFormData());
    const { translate } = useTranslate();
    const [partnerRequired, setPartnerRequired] = useState(false);
    const mode = useMode();

    useOnNext(() => form.submit());

    form.setFieldsValue(FormUtilities.flattenObject(formData));

    const [isFirstNameRequired, setIsFirstNameRequired] = useState(provider.isFirstNameRequired());

    useApplicationChanged(() => {
        setIsFirstNameRequired(provider.isFirstNameRequired());

        const data = provider.getFormData();
        form.setFieldsValue(FormUtilities.flattenObject(data));
        setFormData(data);
    });

    const onFinish = (): void => {
        onValidationFailed();
        provider.next();
    };

    const onValidationFailed = (): void => {
        const values = FormUtilities.rebuildObject(form.getFieldsValue());
        provider.setFormData(values);
        checkPartnerRequired();
    };

    const onChange = (): void => {
        checkPartnerRequired();
    };

    const checkPartnerRequired = (): void => {
        let hasPartnerValues = false;
        for (const field in form.getFieldsValue()) {
            if (field === 'partner.phone') {
                const value = form.getFieldValue(field);
                hasPartnerValues ||= value.phone != null && value.phone !== '';             
            } else if (field.startsWith('partner.')) {
                const value = form.getFieldValue(field);
                hasPartnerValues ||= value != null && value !== '';
            }
        }

        setPartnerRequired(hasPartnerValues);
    };

    const hasZipCodePattern = (): boolean => {
        if (selectedCountry && selectedCountry.postcodePattern) {
            return selectedCountry.postcodePattern !== null && selectedCountry.postcodePattern !== '';
        }
        return false;
    };

    const childGenders = provider.getGenders(isFirstNameRequired, true);
    const genders = provider.getGenders();

    const getPhoneInitialValue = (): PhoneData => {
        return { short: country.code };
    };

    const getPhoneInitialValueChild = (): PhoneData => {
        if (formData.child && formData.child.emergencyNumber) {
            return formData.child.emergencyNumber;
        }
        return getPhoneInitialValue();
    };

    const getPhoneInitialValuePrimaryContact = (): PhoneData => {
        if (formData.primaryContact && formData.primaryContact.phone) {
            return formData.primaryContact.phone;
        }
        return getPhoneInitialValue();
    };

    const getPhoneInitialValuePartner = (): PhoneData => {
        if (formData.partner && formData.partner.phone) {
            return formData.partner.phone;
        }
        return getPhoneInitialValue();
    };

    const getPrimaryEmail = () : string => { 
        onValidationFailed();
        if (formData.primaryContact && formData.primaryContact.email) {
            return formData.primaryContact.email;
        }
        return '';
    };

    const getPartnerEmail = () : string => { 
        onValidationFailed();
        if (formData.partner && formData.partner.email) {
            return formData.partner.email;
        }
        return '';
    };

    useState(() => {
        setTimeout(() => {
            checkPartnerRequired();
            //checkEmailEqual();
        }, 100);
    });

    return (
        
        <Container form={form} layout='vertical' onFinish={onFinish} onFinishFailed={onValidationFailed} onChange={onChange} style={{ display: 'flex' }}>
            <ConfigProvider locale={nl}>
            <div className='section'>
                <Title level={3}>{translate('applications.steps.yourData.childTitle')}</Title>
                <FormItem name='child.firstName' required={isFirstNameRequired}>
                    <Input />
                </FormItem>
                <FormItem name='child.middleName' required={false}>
                    <Input />
                </FormItem>
                <FormItem name='child.lastName' required={true}>
                    <Input />
                </FormItem>
                <FormItem name='child.dateOfBirth' text={translate('applications.data.person.expectedDateOfBirth').toString()} required={true}>
                    <DateInput />
                </FormItem>
                <FormItem name='child.gender' required={isFirstNameRequired}>
                    <GenderSelectionComponent genders={childGenders} />
                </FormItem>
                <FormItem name='child.emergencyNumber' required={true} initialValue={getPhoneInitialValueChild()} >
                    <CountryPhoneInput />
                </FormItem>
            </div>
            {mode === Mode.new ? (<>
                        <div className='section'>
                            <Title level={3}>{translate('applications.steps.yourData.primaryContactTitle')}</Title>
                            <FormItem name='primaryContact.firstName' required={true}>
                                <Input />
                            </FormItem>
                            <FormItem name='primaryContact.initials' required={true}>
                                <Input />
                            </FormItem>
                            <FormItem name='primaryContact.middleName' required={false}>
                                <Input />
                            </FormItem>
                            <FormItem name='primaryContact.lastName' required={true}>
                                <Input />
                            </FormItem>
                            <FormItem name='primaryContact.dateOfBirth' required={true}>
                                <DateInput />
                            </FormItem>
                            <FormItem name='primaryContact.gender' required={false}>
                                <GenderSelectionComponent genders={genders} />
                            </FormItem>
                            <FormItem name='primaryContact.email' required={true} partnerEmail={getPartnerEmail}>
                                <Input />
                            </FormItem>
                            <FormItem name='primaryContact.phone' required={true} initialValue={getPhoneInitialValuePrimaryContact()}>
                                <CountryPhoneInput />
                            </FormItem>

                        </div>
                        <div className='section'>
                            <Title level={3}>{translate('applications.steps.yourData.partnerTitle')}</Title>
                            <FormItem name='partner.firstName' required={partnerRequired}>
                                <Input />
                            </FormItem>
                            <FormItem name='partner.initials' required={partnerRequired}>
                                <Input />
                            </FormItem>
                            <FormItem name='partner.middleName' required={false}>
                                <Input />
                            </FormItem>
                            <FormItem name='partner.lastName' required={partnerRequired}>
                                <Input />
                            </FormItem>
                            <FormItem name='partner.dateOfBirth' required={partnerRequired}>
                                <DateInput />
                            </FormItem>
                            <FormItem name='partner.gender' required={false}>
                                <GenderSelectionComponent genders={genders} />
                            </FormItem>
                            <FormItem name='partner.email' required={partnerRequired} primaryEmail={getPrimaryEmail}>
                                <Input />
                            </FormItem>
                            <FormItem name='partner.phone' required={partnerRequired} initialValue={getPhoneInitialValuePartner()}>
                            <CountryPhoneInput />
                            </FormItem>
                        </div>
                        <div className='section'>
                            <Title level={3}>Uw adres</Title>
                            <FormItem name='street' required={true}>
                                <Input />
                            </FormItem>
                            <FormItem name='number' required={true}>
                                <Input />
                            </FormItem>
                            {hasZipCodePattern() &&
                            <FormItem name='zipCode' required={true} regex={selectedCountry?.postcodePattern} regexMessage='applications.errors.invalidZipCodeFormat'>
                                <Input addonAfter={selectedCountry?.postcodeFormat} />
                            </FormItem>
                            }
                            
                            <FormItem name='city' required={true}>
                                <Input />
                            </FormItem>
                        </div>
                    </>) : <></>}   
                    </ConfigProvider>         
        </Container>
    );
};

function GenderSelectionComponent(props: RadioGroupProps & { genders: GenderSelection[] }): React.ReactElement {
    const { translate } = useTranslate();

    return (
        <Radio.Group {...props}>
            {props.genders.map((x, i) => <Radio.Button key={i} value={x.value}>{ToText(translate, x.label)}</Radio.Button>)}
        </Radio.Group>
    );
}

const Container = styled(Form)`
    display: flex;
    flex-flow: row wrap;
    gap: 15px;

    .section {
        flex: 1;
        min-width: 150px;
    }
`;