import React, { useState } from 'react';
import { useCareTypeStepProvider, useApplicationChanged } from '@/applications/application-provider';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CareTypeReference } from '@/client/models';
import { InfoCard } from '@/components/info-card';
import { useTranslate } from '@/language-provider';
import { CareTypeStepProvider } from '@/applications/care-type-step-provider';

const CareTypeComponent: React.FC<{ careType: CareTypeReference; provider: CareTypeStepProvider }> = ({ careType, provider }) => {
    const [isSelected, setIsSelected] = useState(provider.isCareTypeSelected(careType));

    const { translate } = useTranslate();
    useApplicationChanged(() => {
        setIsSelected(provider.isCareTypeSelected(careType));
    });

    function ageLine(careType: CareTypeReference): string {
        if (careType.toAge != null) {
            return translate('applications.steps.careType.fromToAge', { from: careType.fromAge, to: careType.toAge }).toString();
        }
        return translate('applications.steps.careType.fromAge', { from: careType.fromAge }).toString();
    }

    return (
        <InfoCard
            icon={<ArrowRightOutlined />}
            title={careType.name}
            lines={[ageLine(careType)]}
            onClick={() => provider.toggleCareType(careType)} isSelected={isSelected} />
    );
};

export const CareTypeStep: React.FC = () => {
    const provider = useCareTypeStepProvider();
    const careTypes = provider.getCareTypes();

    return (<>{careTypes.map(x => <CareTypeComponent key={x.name} careType={x} provider={provider} />)}</>);
};