import {combineReducers, Reducer, AnyAction} from 'redux';
import {ApplicationState} from '@/store/application-state';
import {reducer as applicationReducer} from '@/store/application/reducer';
import {reducer as generalReducer} from '@/store/general/reducer';
import {GeneralActions} from '@/store/general/actions';

export const createRootReducer = (): Reducer<ApplicationState> => {

    const appReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
        application: applicationReducer,
        general: generalReducer
    });

    return (state: ApplicationState, action: AnyAction): ApplicationState => {
        if (action.type === GeneralActions.resetApp().type) {
            state = undefined;
        }

        return appReducer(state, action);
    };
};