import React from 'react';
import { WelcomeStep, CareTypeStep, CarePackageStep, SchoolStep, LocationsStep, YourDataStep, QuestionsStep, SummaryStep } from '@/screens/application/steps';
import { TranslateFunction, TranslatePlaceholderData } from 'react-localize-redux';
import { WizardPart, WizardPartType } from './wizard-part';
import { ContentResponse } from '@/client/models';
import { ApplicationProvider } from './application-provider';

type Callback = () => void;

export type DisplayText = TranslatableDisplayText | DirectDisplayText | ComposedDisplayText;
export type TranslatableDisplayText = {
    type: 'translatable',
    id: string,
    data: TranslatePlaceholderData
};
export type DirectDisplayText = {
    type: 'direct',
    text: string
};
export type ComposedDisplayText = {
    type: 'composed',
    items: DisplayText[]
};

export const DirectText = (text: string): DirectDisplayText => ({ type: 'direct', text });
export const TranslatableText = (id: string, data?: TranslatePlaceholderData): TranslatableDisplayText => ({ type: 'translatable', id, data });
export const ComposedText = (items: DisplayText[]): ComposedDisplayText => ({ type: 'composed', items });
export const ToText = (translate: TranslateFunction, displayText: DisplayText): string => {
    if (displayText === undefined) {
        return '';
    }

    switch (displayText.type) {
        case 'direct': return displayText.text;
        case 'translatable': return translate(displayText.id, displayText.data).toString();
        case 'composed': return displayText.items.map(x => ToText(translate, x)).join(' ');
    }
};

export interface Step {
    key: WizardPartType;
    title?: DisplayText;
    subTitle?: DisplayText;
    description?: DisplayText;
    component?: React.ReactElement;
    isCompleted: boolean;
    isActive?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state?: any;
    content?: ContentResponse;
    nextButtonActive?: boolean;
}

export const Steps = {
    [WizardPart.welcome]: { key: WizardPart.welcome, title: TranslatableText('applications.steps.welcome.title'), isCompleted: false, component: <WelcomeStep /> },
    [WizardPart.careType]: { key: WizardPart.careType, title: TranslatableText('applications.steps.careType.title'), isCompleted: false, component: <CareTypeStep /> },
    [WizardPart.carePackage]: { key: WizardPart.carePackage, title: TranslatableText('applications.steps.carePackage.title'), isCompleted: false, component: <CarePackageStep /> },
    [WizardPart.school]: { key: WizardPart.school, title: TranslatableText('applications.steps.school.title'), isCompleted: false, component: <SchoolStep /> },
    [WizardPart.locations]: { key: WizardPart.locations, title: TranslatableText('applications.steps.locations.title'), isCompleted: false, component: <LocationsStep /> },
    [WizardPart.yourData]: { key: WizardPart.yourData, title: TranslatableText('applications.steps.yourData.title'), isCompleted: false, component: <YourDataStep /> },
    [WizardPart.questions]: { key: WizardPart.questions, title: TranslatableText('applications.steps.questions.title'), isCompleted: false, component: <QuestionsStep /> },
    [WizardPart.summary]: { key: WizardPart.summary, title: TranslatableText('applications.steps.summary.title'), isCompleted: false, component: <SummaryStep /> }
};

export class StepsProvider {
    private steps = new Array<Step>();
    private currentKey: string;
    private changedListeners = new Array<Callback>();
    private nextListeners = new Array<Callback>();

    constructor() {
        this.moveTo = this.moveTo.bind(this);
        this.moveNext = this.moveNext.bind(this);
        this.movePrevious = this.movePrevious.bind(this);
        this.getSteps = this.getSteps.bind(this);
        this.add = this.add.bind(this);
        this.insertAfter = this.insertAfter.bind(this);
        this.remove = this.remove.bind(this);
        this.setContent = this.setContent.bind(this);
        this.setDescription = this.setDescription.bind(this);
        this.getCurrentStepIndex = this.getCurrentStepIndex.bind(this);
        this.getCurrentStep = this.getCurrentStep.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.onNext = this.onNext.bind(this);
        this.next = this.next.bind(this);
        this.isNextActive = this.isNextActive.bind(this);
    }

    public initialize(): void {
        this.add(Steps.welcome);
        this.add(Steps.careType);
        this.add(Steps.locations);
        this.add(Steps.questions);
        this.add(Steps.yourData);
        this.add(Steps.summary);
        this.currentKey = this.steps[0].key;

        this.changed();
    }

    public getSteps(): Step[] {
        // Always return a new array or react useState functions wont work (compared by reference for differences and will not update when reference is the same)
        return [...this.steps];
    }

    public canGoToStep(key: WizardPartType): boolean {
        const { index } = this.findStep(key);
        return this.canGoToStepIndex(index);
    }

    public canGoToNextStep(key: WizardPartType): boolean {
        const { index } = this.findStep(key);
        return this.canGoToStepIndex(index + 1);
    }

    public isPreviousCompleted(key: WizardPartType): boolean {
        const { index } = this.findStep(key);
        if (index === 0) {
            return true;
        }

        const previousStep = this.steps[index - 1];
        return previousStep.isCompleted;
    }

    private canGoToStepIndex(index: number): boolean {
        const step = this.steps[index];
        if (index === 0 || step?.isActive || step?.isCompleted) {
            return true;
        }

        for (let i = 0; i < index; i++) {
            const previousStep = this.steps[i];
            if (!previousStep.isCompleted) {
                return false;
            }
        }

        return true;
    }

    public complete(key: WizardPartType): void {
        const { step } = this.findStep(key);
        if (step != null && step.isCompleted === false) {
            step.isCompleted = true;
            this.changed();
        }
    }

    public incomplete(key: WizardPartType): void {
        const { step } = this.findStep(key);
        if (step != null && step.isCompleted === true) {
            step.isCompleted = false;
            this.changed();
        }
    }

    public add(step: Step): void {
        this.steps.push(step);
    }

    public insertAfter(key: WizardPartType, step: Step): boolean {
        const { index } = this.findStep(key);
        if (index < 0) {
            return false;
        }

        this.steps.splice(index + 1, 0, step);

        this.changed();
        return true;
    }

    public remove(key: WizardPartType): void {
        const { index } = this.findStep(key);
        if (index < 0) {
            return;
        }

        this.steps.splice(index, 1);
        this.changed();
    }

    public setDescription(key: WizardPartType, description: DisplayText): void {
        const { step } = this.findStep(key);
        if (step == null) {
            return;
        }

        step.description = description;
        this.changed();
    }

    public setContent(key: WizardPartType, content: ContentResponse): void {
        const { step } = this.findStep(key);
        if (step == null) {
            return;
        }

        step.content = content;
        this.changed();
    }

    public moveNext(): number {
        const nextIndex = this.getCurrentStepIndex() + 1;
        if (nextIndex >= this.steps.length) {
            return -1;
        }
        return this.moveTo(nextIndex);
    }

    public movePrevious(): number {
        const nextIndex = this.getCurrentStepIndex() - 1;
        if (nextIndex < 0) {
            return -1;
        }

        return this.moveTo(nextIndex);
    }

    public moveTo(index: number): number {
        if (index < 0 || index >= this.steps.length) {
            return -1;
        }

        const currentIndex = this.getCurrentStepIndex();
        if (currentIndex === index) {
            return index;
        }

        this.currentKey = this.steps[index].key;
        if (this.currentKey === 'carePackage') {
            const key = ApplicationProvider.locationPackageKey(this.steps[index].state.locationId, this.steps[index].state.carePackageId);
            this.currentKey = key;
        }

        this.changed();
        return index;
    }

    public moveToStep(key: WizardPartType): number {
        const { index } = this.findStep(key);
        if (index < 0) {
            return -1;
        }

        return this.moveTo(index);
    }

    public findStep(key: WizardPartType): { index: number; step: Step } {
        const index =this.getStepIndex(key);
        const step = index >= 0 ? this.steps[index] : undefined;
        return { index, step };
    }

    public getCurrentStep(): Step {
        return this.findStep(this.currentKey)?.step;
    }

    private getStepIndex(key: WizardPartType): number {
        let index = -1;
        if (key.startsWith('carePackage')) {
            const splittedKey = key.split('|');
            if (splittedKey.length === 3) {
                const locationId = splittedKey[1];
                const carePakackageId = splittedKey[2];
                index = this.steps.findIndex(x => x.key === 'carePackage' &&
                    x.state.locationId === locationId &&
                    x.state.carePackageId === carePakackageId);
            }
            else {
                index = this.steps.findIndex(x => x.key === key);
            }
        } else {
            index = this.steps.findIndex(x => x.key === key);
        }
        return index;
    }

    public getCurrentStepIndex(): number {
        return this.getStepIndex(this.currentKey);
    }

    public next(): void {
        for (const callback of this.nextListeners) {
            callback();
        }
        if (!this.nextListeners.length) {
            if (this.getCurrentStep().isCompleted) {
                this.moveNext();
            }
        }
    }

    public done(): void {
        for (const callback of this.nextListeners) {
            callback();
        }
        
        let current: Step;
        for (const step of this.steps) {
            if (current != null && !current.isCompleted) {
                return;
            }
            current = step;
        }
        this.complete(current.key);
    }

    private changed(): void {
        for (const callback of this.changedListeners) {
            callback();
        }
    }

    public onChanged(callback: Callback): Callback {
        this.changedListeners.push(callback);

        return () => {
            this.changedListeners.splice(this.changedListeners.indexOf(callback), 1);
        };
    }

    public isNextActive(): boolean {
        if (this.nextListeners.length > 0) {
            return true;
        }
        const index = this.getCurrentStepIndex();
        return this.canGoToStepIndex(index);
    }

    public onNext(callback: Callback): Callback {

        this.nextListeners.push(callback);
        this.changed();

        return () => {
            this.nextListeners.splice(this.nextListeners.indexOf(callback), 1);
            this.changed();
        };
    }
}