import { Gender } from './gender';
import moment from 'moment';
import { PhoneData } from '@/applications/person-data';

export interface Child {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    gender: Gender;
    dateOfBirth: moment.Moment;
    emergencyNumber?: PhoneData;
}

export interface ChildDetails {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    gender: Gender;
    dateOfBirth: moment.Moment;
    emergencyNumber?: string;
}

export function onDeserializeChild(data: ChildDetails): ChildDetails {
    if (data == null) {
        return data;
    }

    if (data.dateOfBirth != null) {
        data.dateOfBirth = moment(data.dateOfBirth);
    }

    return data;
}

export function onSerializeChild(data: ChildDetails): ChildDetails {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.dateOfBirth = data.dateOfBirth?.toISOString(true) as unknown as moment.Moment;

    return serialized;
}
