export type WizardPartType = WizardPart | keyof typeof WizardPart | string;

export enum WizardPart {
    welcome = 'welcome',
    school = 'school',
    careType = 'careType',
    carePackage = 'carePackage',
    locations = 'locations',
    yourData = 'yourData',
    questions = 'questions',
    summary = 'summary'
}
