import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '@/store/configure-store';
import { LocalizeProvider } from 'react-localize-redux';
import { LanguageProvider } from '@/language-provider';
import { AppComponent } from '@/app-component';
import { ThemeProvider } from 'styled-components';
import { LightTheme } from '@/themes/default-theme';
import { EnvironmentMode, getEnvironment } from '@/environment-mode';

import 'antd/dist/antd.css';
import { ApplicationScreen } from './screens/application-screen';
import { TenantProviderComponent } from './tenant/tenant-provider';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import axios from 'axios';

const env = getEnvironment();

console.log(`🚀 Version ${env.buildnumber} build from ${env.commit} and currently running in ${env.environment} mode`);

LogRocket.init('ylxpze/applications', {
    release: env.commit
});
setupLogRocketReact(LogRocket);

axios.defaults.headers.common['Tenant'] = env.tenant;
if (env.environment === EnvironmentMode.development) {
    console.log('activated develop mode');

    if (env.backendUrl != null) {
        axios.defaults.headers.common['BackendUrl'] = env.backendUrl;
    }
} else {
    if ('serviceWorker' in globalThis.navigator) {
        // Use the window load event to keep the page load performant
        globalThis.addEventListener('load', () => {
            globalThis.navigator.serviceWorker.register('/service-worker.js');
        });
    }
}

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={LightTheme}>
            <TenantProviderComponent>
                <LocalizeProvider>
                    <LanguageProvider>
                        <AppComponent>
                            <ApplicationScreen />
                        </AppComponent>
                    </LanguageProvider>
                </LocalizeProvider>
            </TenantProviderComponent>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);