import React, { ReactNode } from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';

interface Props {
    code?: string;
    title: string;
    icon?: string | ReactNode;
    imageUrl?: string;
    lines: string[];
    isSelected?: boolean;
    onClick?: () => void;
}

export const InfoCard: React.FC<Props> = props => {
    let text: string = undefined;
    if (isEmpty(props.imageUrl) && props.icon == null) {
        text = props.code ?? props.title;
        if (text.length > 4) {
            text = text.substring(0, 4);
        }
    }

    let size = 64;
    if (props.lines.length === 1) {
        size = 32;
    } else if (props.lines.length === 0) {
        size = 16;
    }

    return (
        <Wrapper onClick={props.onClick} isSelected={props.isSelected ?? false}>
            <Avatar size={size} icon={props.icon} src={props.imageUrl} style={{ verticalAlign: 'top', marginRight: '16px' }}>{text}</Avatar>
            <ContentWrapper>
                <h1>{props.title}</h1>
                {props.lines.map((element, i) => <div key={i}>{element}&nbsp;</div>)}
            </ContentWrapper>
        </Wrapper>
    );
};

function isEmpty(value?: string): boolean {
    if (value == null) {
        return true;
    }

    return value.trim() === '';
}

const Wrapper = styled.div<{ isSelected: boolean }>`
    vertical-align: top;
    border: 1px solid transparent;
    background-color: ${props => props.isSelected ? (props.theme.primary? props.theme.primary: '#1890ff') : props.theme.background};
    border-radius: 16px;
    color: ${props => props.isSelected ? props.theme.background : props.theme.inverted};
    padding: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    div > h1 {
        color: ${props => props.isSelected ? props.theme.background : props.theme.primary};
        margin-bottom: 0;
        font-weight: normal;
        font-size: 120%;
    }

    :hover {
        border-color: rgba(0, 0, 0, 0.09);
        box-shadow: 0 2px 8px rgba(0,0,0, 0.09);
        cursor: pointer;
    }
`;

const ContentWrapper = styled.div`
    vertical-align: top;
    display: inline-block;
`;