import React, { useContext, useEffect, useState } from 'react';
import { OptionsApi } from '@/client/api';
import { ThemeContext } from 'styled-components';
import { getEnvironment } from '@/environment-mode';
import { useDispatch } from 'react-redux';
import { GeneralActions } from '@/store/general/actions';

const TenantContext = React.createContext<TenantData>(undefined);

interface TenantData {
    tenant: string;
    mainColor?: string;
    accentColor?: string;
    companyPictureUrl?: string;
    companyWebsite?: string;
    companyName?: string;
}

export const useTenant = (): TenantData => {
    return useContext(TenantContext);
};

export const TenantProviderComponent: React.FC = ({ children }) => {
    const env = getEnvironment();

    const dispatch = useDispatch();

    const themeContext = useContext(ThemeContext);
    const [data, setData] = useState<TenantData>({ tenant: env.tenant });

    if (env.tenant !== data.tenant) {
        setData({ tenant: env.tenant });
    }

    useEffect(() => {
        if (data.tenant == null) {
            return;
        }

        const api = new OptionsApi(getEnvironment().apiUrl);
        dispatch(GeneralActions.loadTimeSlots());

        api.get({ ['Tenant']: data.tenant }).then(response => {

            const result = {
                tenant: data.tenant,
                mainColor: response.data.mainColor,
                accentColor: response.data.accentColor,
                companyPictureUrl: response.data.companyPictureUrl,
                companyWebsite: response.data.companyWebsite,
                companyName: response.data.companyName,
            };

            themeContext.primary = result.mainColor;
            themeContext.secondary = result.accentColor;

            setData(result);
        });
    }, [data.tenant]);

    return (
        <TenantContext.Provider value={data}>
            {children}
        </TenantContext.Provider>
    );
};