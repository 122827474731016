/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {
    LocationsResponse,
    TimeSlotsResponse,
    CarePackageDetailsResponse,
    CareType,
    RegistrationOptionsResponse,
    QuestionsResponse,
    AllContentResponse,
    NewApplicationCommand,
    AddChildCommand
} from '@/client/models';
import { GenericError } from '@/store/generic-error';
import { ActionsUnion } from '@/store/types';
import { action } from '@/store/decorators';
import { Action } from '@/store/action';
import moment from 'moment';

export class ApplicationActions {
    public static __className = 'ApplicationActions';
    
    @action
    static loadRegistrationOptions() { return Action('loadRegistrationOptions'); }
    @action
    static loadRegistrationOptionsSuccess(response: RegistrationOptionsResponse) { return Action('loadRegistrationOptionsSuccess', { ...response }); }
    @action
    static loadRegistrationOptionsFailed(error: GenericError<typeof ApplicationActions.loadRegistrationOptions>) { return Action('loadRegistrationOptionsFailed', { error }); }
     
    @action
    static loadQuestions() { return Action('loadQuestions'); }
    @action
    static loadQuestionsSuccess(response: QuestionsResponse) { return Action('loadQuestionsSuccess', { ...response }); }
    @action
    static loadQuestionsFailed(error: GenericError<typeof ApplicationActions.loadQuestions>) { return Action('loadQuestionsFailed', { error }); }
   
    @action
    static loadLocations(date: moment.Moment, schoolId: string, careType: CareType) { return Action('loadLocations', { date, schoolId, careType }); }
    @action
    static loadLocationsSuccess(response: LocationsResponse) { return Action('loadLocationsSuccess', { ...response }); }
    @action
    static loadLocationsFailed(error: GenericError<typeof ApplicationActions.loadLocations>) { return Action('loadLocationsFailed', { error }); }

    @action
    static loadCarePackageDetails(carePackageId: string, locationId: string, schoolId?: string, date?: moment.Moment) { return Action('loadCarePackageDetails', { carePackageId, locationId, schoolId, date }); }
    @action
    static loadCarePackageDetailsSuccess(response: CarePackageDetailsResponse) { return Action('loadCarePackageDetailsSuccess', { ...response }); }
    @action
    static loadCarePackageDetailsFailed(error: GenericError<typeof ApplicationActions.loadCarePackageDetails>) { return Action('loadCarePackageDetailsFailed', { error }); }

    @action
    static loadTimeSlots() { return Action('loadTimeSlots'); }
    @action
    static loadTimeSlotsSuccess(response: TimeSlotsResponse) { return Action('loadTimeSlotsSuccess', { ...response }); }
    @action
    static loadTimeSlotsFailed(error: GenericError<typeof ApplicationActions.loadTimeSlots>) { return Action('loadTimeSlotsFailed', { error }); }
     
    @action
    static loadContent(languageCode: string) { return Action('loadContent', { languageCode }); }
    @action
    static loadContentSuccess(response: AllContentResponse) { return Action('loadContentSuccess', { ...response }); }
    @action
    static loadContentFailed(error: GenericError<typeof ApplicationActions.loadContent>) { return Action('loadContentFailed', { error }); }

    @action
    static postNewApplication(command: NewApplicationCommand) { return Action('postNewApplication', { command }); }
    @action
    static postNewApplicationSuccess(response: NewApplicationCommand) { return Action('postNewApplicationSuccess', { ...response }); }
    @action
    static postNewApplicationFailed(error: GenericError<typeof ApplicationActions.postNewApplication>) { return Action('postNewApplicationFailed', { error }); }

    @action
    static postAddChild(command: AddChildCommand) { return Action('postAddChild', { command }); }
    @action
    static postAddChildSuccess() { return Action('postAddChildSuccess'); }
    @action
    static postAddChildFailed(error: GenericError<typeof ApplicationActions.postAddChild>) { return Action('postAddChildFailed', { error }); }
}

export type Actions = ActionsUnion<typeof ApplicationActions>;