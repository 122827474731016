import { ProgressReporter } from './progress-reporter';
import { QuestionModel } from '@/client/models';
import { WizardPart } from './wizard-part';
import { LocationPackage } from './location-package';
import { DirectText, DisplayText, TranslatableText } from './steps-provider';

export interface StepData {
    packages?: LocationPackage[];
    answers: AnswerData[];
}

export interface AnswerData {
    questionId: string;
    answer: string;
    answerId?: string;
}

export interface QuestionGroup {
    title: DisplayText;
    carePackageId?: string;
    questions: QuestionModel[];
}

export class QuestionsStepProvider {
    private data: StepData;
    private reporter: ProgressReporter;
    private questions: QuestionModel[];

    constructor(data: StepData, reporter: ProgressReporter) {
        this.data = data;
        this.reporter = reporter;

        this.questions = [];

        this.initialize = this.initialize.bind(this);
        this.getQuestionGroups = this.getQuestionGroups.bind(this);
    }

    public initialize(questions: QuestionModel[]): void {
        this.questions = questions;
    }

    public getQuestionGroups(): QuestionGroup[] {
        const groups = new Array<QuestionGroup>();
        for (const question of this.questions) {
            let group: QuestionGroup;
            if (question.packageId == null) {
                group = groups.find(g => g.carePackageId == null);
                if (group == null) {
                    group = { title: TranslatableText('applications.steps.questions.defaultGroup'),  questions: [] };
                    groups.unshift(group);
                }
            } else {
                const carePackage = this.data.packages.find(p => p.carePackagePakketLabelId === question.packageId);
                if (carePackage == null) {
                    continue;
                }
                group = groups.find(value => value.carePackageId === question.packageId);
                if (group == null) {
                    group = { title: DirectText(carePackage.carePackageName), carePackageId: carePackage.carePackageId, questions: [] };
                    groups.push(group);
                }
            }
            group.questions.push(question);
        }
        return groups;
    }

    public getAnswers(): AnswerData[] {
        return [...this.data.answers];
    }

    public setAnswers(answers: AnswerData[]): void {
        for (const answer of answers) {
            const current = this.data.answers.find(a=>a.questionId === answer.questionId);
            if (current == null) {
                this.data.answers.push(answer);          
            } else {
                current.answer = answer.answer;
                current.answerId = answer.answerId;
            }
        }
    }

    public next(): void {
        this.reporter.partChanged(WizardPart.questions, true);
    }
}