/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericError } from '@/store/generic-error';
import moment from 'moment';
import { Status } from '@/store/status';

export function initValue<P>(initialValue?: P): RetrievedValue<P> {
    return { status: Status.Init, on: moment(), value: initialValue };
}

export function loadingValue<P>(initialValue?: P): RetrievedValue<P> {
    return { status: Status.Loading, on: moment(), value: initialValue };
}

export function loadedValue<P>(value: P = undefined): RetrievedValue<P> {
    return { status: Status.Loaded, on: moment(), value };
}

export function combine<P>(values: RetrievedValue<P>[], value: RetrievedValue<P>, keySelector: (value: P) => any): RetrievedValue<P>[] {
    const newKey = keySelector(value.value);

    const clonedValues = [...values];

    const existingIndex = clonedValues.findIndex(x => keySelector(x.value) === newKey);
    if (existingIndex !== -1) {
        clonedValues.splice(existingIndex, 1, value);
    } else {
        clonedValues.push(value);
    }

    return clonedValues;
}

export function errorValue<P>(error: GenericError, value?: P): RetrievedValue<P> {
    return { status: Status.Error, on: moment(), error, value };
}

export interface ActionResult {
    on?: moment.Moment;
    status: Status;
    error?: GenericError;
}

export interface RetrievedValue<P> extends ActionResult {
    value?: P;
}
