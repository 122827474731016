import {State} from './state';
import { initValue, loadingValue, loadedValue, errorValue, combine } from '@/store/retrieved-value';
import { Actions, ApplicationActions } from './actions';
import { isActionFromContainer } from '@/store/action';
import { CarePackageDetailsResponse } from '@/client/models';

const initialState: State = {
    options: initValue(),
    locations: initValue(),
    questions: initValue(),
    carePackageDetails: [],
    content: initValue(),
    timeSlots: initValue(),
    application: initValue(),
    addChild: initValue(),
};

export const reducer = (state = initialState, action: Actions): State => {
    if (!isActionFromContainer(action, ApplicationActions)) {
        return state;
    }

    switch (action.action) {

        case 'loadRegistrationOptions':
            return { ...state, options: loadingValue(state.options.value) };
        case 'loadRegistrationOptionsSuccess':
            return { ...state, options: loadedValue(action.payload) };
        case 'loadRegistrationOptionsFailed':
            return { ...state, options: errorValue(action.payload.error) };

        case 'loadQuestions':
            return { ...state, questions: loadingValue(state.questions.value) };
        case 'loadQuestionsSuccess':
            return { ...state, questions: loadedValue(action.payload) };
        case 'loadQuestionsFailed':
            return { ...state, questions: errorValue(action.payload.error) };

        case 'loadLocations':
            return { ...state, locations: loadingValue(state.locations.value) };
        case 'loadLocationsSuccess':
            return { ...state, locations: loadedValue(action.payload.locations) };
        case 'loadLocationsFailed':
            return { ...state, locations: errorValue(action.payload.error) };

        case 'loadContent':
            return { ...state, content: loadingValue(state.content.value) };
        case 'loadContentSuccess':
            return { ...state, content: loadedValue(action.payload) };
        case 'loadContentFailed':
            return { ...state, content: errorValue(action.payload.error) };

        case 'loadCarePackageDetails':
            return { ...state, carePackageDetails: combine(state.carePackageDetails, loadingValue({ carePackageId: action.payload.carePackageId, locationId: action.payload.locationId, schoolId: action.payload.schoolId, startAt: action.payload.date } as CarePackageDetailsResponse), x => `${x.carePackageId}:${x.locationId}:${x.schoolId}:${x.startAt}`) };
        case 'loadCarePackageDetailsSuccess':
            return { ...state, carePackageDetails: combine(state.carePackageDetails, loadedValue(action.payload), x => `${x.carePackageId}:${x.locationId}:${x.schoolId}:${x.startAt}`) };
        case 'loadCarePackageDetailsFailed':
            return { ...state, carePackageDetails: combine(state.carePackageDetails, errorValue(action.payload.error), x => `${x.carePackageId}:${x.locationId}:${x.schoolId}:${x.startAt}`) };

        case 'loadTimeSlots':
            return { ...state, timeSlots: loadingValue(state.timeSlots.value) };
        case 'loadTimeSlotsSuccess':
            return { ...state, timeSlots: loadedValue(action.payload.timeSlots) };
        case 'loadTimeSlotsFailed':
            return { ...state, timeSlots: errorValue(action.payload.error) };

        case 'postNewApplication':
            return { ...state, application: loadingValue(state.application.value) };
        case 'postNewApplicationSuccess':
            return { ...state, application: loadedValue(action.payload) };
        case 'postNewApplicationFailed':
            return { ...state, application: errorValue(action.payload.error) };

        case 'postAddChild':
            return { ...state, addChild: loadingValue(state.addChild.value) };
        case 'postAddChildSuccess':
            return { ...state, addChild: loadedValue() };
        case 'postAddChildFailed':
            return { ...state, addChild: errorValue(action.payload.error) };
            
        default:
            return state;
    }
};