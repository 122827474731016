import { PostalCodeSuggestions } from '../models/postal-code-suggestions';
import { AutoComplete } from '../models/auto-complete';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class AddressApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getPostalCode(postalCode?: string, headers?: AxiosHeaders): Promise<AxiosResponse<PostalCodeSuggestions>> {
        const request = {
            url: this.baseUrl + '/Address/PostalCode',
            method: 'GET',
            responseType: 'json',
            params: {
                postalCode: postalCode,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<PostalCodeSuggestions>(request);
        

        return response;
    }

    public async getSuggestion(postalCode?: string, number?: string, headers?: AxiosHeaders): Promise<AxiosResponse<AutoComplete>> {
        const request = {
            url: this.baseUrl + '/Address/Suggestion',
            method: 'GET',
            responseType: 'json',
            params: {
                postalCode: postalCode,
                number: number,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<AutoComplete>(request);
        

        return response;
    }
}