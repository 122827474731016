import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Store} from 'redux';
import {useDispatch, useSelector, useStore} from 'react-redux';
import styled from 'styled-components';
import {Error} from '@/app-error-boundry';
import {useActiveLanguage} from '@/language-provider';
import {Status} from '@/store/status';
import {ApplicationState} from '@/store/application-state';
import {ApplicationActions} from '@/store/application/actions';
import {carePackageDetailsRetrievedSelector, contentRetrievedSelector, questionsRetrievedSelector, registrationOptionsRetrievedSelector} from '@/store/application/selectors';
import {CareType, ContentResponse, QuestionModel, QuestionType, RegistrationOptionsResponse} from '@/client/models';
import {DirectText, Steps, StepsProvider, TranslatableText} from './steps-provider';
import {WizardPart, WizardPartType} from './wizard-part';
import {LoadingContainer} from '@/components/loading-container';
import {WelcomeStepProvider} from './welcome-step-provider';
import {WelcomeValidator} from './welcome-validator';
import {CareTypeValidator} from './care-type-validator';
import {CareTypeStepProvider} from './care-type-step-provider';
import {SchoolValidator} from './school-validator';
import {SchoolStepProvider} from './school-step-provider';
import {LocationValidator} from './location-validator';
import {LocationStepCallback, LocationStepProvider} from './location-step-provider';
import {CarePackageValidator} from './care-package-validator';
import {CarePackageStepCallback, CarePackageStepProvider} from './care-package-step-provider';
import {CarePackageStep} from '@/screens/application/steps';
import {YourDataStepProvider} from './your-data-step-provider';
import {YourDataValidator} from './your-data-validator';
import {QuestionsStepProvider} from './questions-step-provider';
import {QuestionsValidator} from './questions-validator';
import {SummaryValidator} from './summary-validator';
import {Validator} from './validator';
import {ApplicationData, Mode} from './application-data';
import {LocationPackage} from './location-package';
import {timeSlotsSelector} from '@/store/general/selectors';
import {ProgressReporter} from './progress-reporter';
import {ApplicationDataConverter} from './application-data-converter';
import {NetworkUtils} from '@/networking/network-utils';
import {v4 as uuidv4} from 'uuid';

const ApplicationContext = React.createContext<ApplicationProvider>(undefined);
type Callback = () => void;

export class ApplicationProvider implements ProgressReporter, LocationStepCallback, CarePackageStepCallback {
    private store: Store<ApplicationState>;
    private readonly data: ApplicationData;
    private contents: ContentResponse[];

    private readonly changedListeners: Callback[];

    private validators: { [key: string]: Validator } = {
        [WizardPart.careType]: new CareTypeValidator(),
        [WizardPart.school]: new SchoolValidator(),
        [WizardPart.locations]: new LocationValidator(),
        [WizardPart.yourData]: new YourDataValidator(),
        [WizardPart.summary]: new SummaryValidator(),
    };

    private readonly stepsProvider: StepsProvider;
    private readonly welcomeStepProvider: WelcomeStepProvider;
    private readonly welcomeValidator: WelcomeValidator;
    private readonly careTypeStepProvider: CareTypeStepProvider;
    private readonly schoolStepProvider: SchoolStepProvider;
    private readonly locationStepProvider: LocationStepProvider;
    private readonly carePackageStepProvider: CarePackageStepProvider;
    private readonly carePackageValidator: CarePackageValidator;
    private readonly yourDataStepProvider: YourDataStepProvider;
    private readonly questionsStepProvider: QuestionsStepProvider;
    private readonly questionsValidator: QuestionsValidator;

    constructor(store: Store<ApplicationState>) {
        this.changedListeners = new Array<Callback>();
        this.store = store;

        const applicationId = NetworkUtils.getApplicationId();

        this.data = {
            applicationId: uuidv4(),
            existingApplicationId: applicationId,
            mode: applicationId == null ? Mode.new : Mode.add,
            packages: [],
            answers: []
        };

        this.stepsProvider = new StepsProvider();

        this.welcomeStepProvider = new WelcomeStepProvider(this.data, this);
        this.welcomeValidator = new WelcomeValidator();
        this.validators[WizardPart.welcome] = this.welcomeValidator;

        this.careTypeStepProvider = new CareTypeStepProvider(this.data, this);

        this.schoolStepProvider = new SchoolStepProvider(this.data, this);

        this.locationStepProvider = new LocationStepProvider(this.data, this, this);

        this.carePackageStepProvider = new CarePackageStepProvider(this.data, this);
        this.carePackageValidator = new CarePackageValidator();
        this.validators['carePackage'] = this.carePackageValidator;

        this.yourDataStepProvider = new YourDataStepProvider(this.data, this);

        this.questionsStepProvider = new QuestionsStepProvider(this.data, this);
        this.questionsValidator = new QuestionsValidator();
        this.validators[WizardPart.questions] = this.questionsValidator;

        this.partChanged = this.partChanged.bind(this);
        this.partIncomplete = this.partIncomplete.bind(this);
        this.partComplete = this.partComplete.bind(this);
        this.carePackageSelected = this.carePackageSelected.bind(this);
        this.carePackageDeselected = this.carePackageDeselected.bind(this);
        this.carePackageChanged = this.carePackageChanged.bind(this);
        this.initializeQuestions = this.initializeQuestions.bind(this);
        this.initializeContent = this.initializeContent.bind(this);
        this.onSummaryComplete = this.onSummaryComplete.bind(this);
        this.getData = this.getData.bind(this);
        this.setRemarks = this.setRemarks.bind(this);
    }

    public initializeOptions(options: RegistrationOptionsResponse): void {
        this.stepsProvider.initialize();
        this.stepsProvider.onChanged(() => this.onStepChanged());

        this.welcomeStepProvider.initialize(options.countries, options.municipalities);
        this.welcomeValidator.initialize(options.countries);
        this.careTypeStepProvider.initialize(options.careTypes);
        this.schoolStepProvider.initialize(options.schools);
        this.locationStepProvider.initialize();
        this.yourDataStepProvider.initialize();
    }

    public initializeQuestions(questions: QuestionModel[]): void {
        if (questions == null || questions.length === 0) {
            this.stepsProvider.remove(WizardPart.questions);
            if (this.stepsProvider.getCurrentStep()?.key === WizardPart.questions) {
                this.stepsProvider.movePrevious();
            }
            return;
        }

        this.questionsStepProvider.initialize(questions);
        this.questionsValidator.initialize(questions);

        this.changed();
    }

    public initializeContent(contents: ContentResponse[]): void {
        if (contents == null || contents.length === 0) {
            return;
        }
        this.contents = contents;
        for (const content of contents) {
            this.stepsProvider.setContent(content.code, content);
        }
    }

    public onSummaryComplete(): void {        
        if (this.data.mode === Mode.new) {
            this.store.dispatch(ApplicationActions.postNewApplication(new ApplicationDataConverter().toNewCommand(this.data)));
        } else {
            this.store.dispatch(ApplicationActions.postAddChild(new ApplicationDataConverter().toAddCommand(this.data)));
        }
    }

    public setRemarks(remarks?: string): void {
        this.data.remarks = remarks;
        this.changed();
    }

    public getData(): ApplicationData {
        return this.data;
    }

    private onStepChanged(): void {
        const step = this.stepsProvider.getCurrentStep();
        if (step?.key?.startsWith('carePackage') && step.state != null) {
            this.carePackageValidator.initialize(step.state.locationId, step.state.carePackageId);
        }
        this.changed();
    }

    public partComplete(part: WizardPartType): void {
        this.stepsProvider.complete(part);

        if (part === WizardPart.welcome) {
            this.onWelcomeComplete();
        } else if (part === WizardPart.careType) {
            this.onCareTypeComplete();
        } else if (part === WizardPart.school) {
            this.onSchoolComplete();
        } else if (part === WizardPart.locations) {
            this.onLocationComplete();
        } else if (part === WizardPart.questions) {
            this.onQuestionsComplete();
        } else if (part === WizardPart.yourData) {
            this.onYourDataComplete();
        } else if (part === WizardPart.summary) {
            this.onSummaryComplete();
        }
    }

    public partIncomplete(part: WizardPartType): void {
        this.stepsProvider.incomplete(part);
        if (part !== WizardPart.welcome) {
            this.stepsProvider.setDescription(part, undefined);
        }

        switch (part) {
            case WizardPart.careType:
                this.onCareTypeIncomplete();
                break;
            case WizardPart.school:
                this.onLocationIncomplete();
                break;
            case WizardPart.locations:
                this.onLocationIncomplete();
                break;
            case WizardPart.questions:
                this.onQuestionsIncomplete();
                break;
            case WizardPart.yourData:
                this.onQuestionsIncomplete();
                break;
            case WizardPart.summary:
                this.onQuestionsIncomplete();
                break;
        }
    }

    public partChanged(part: WizardPartType, autoComplete?: boolean): void {
        const isCompleted = this.stepsProvider.findStep(part)?.step?.isCompleted;
        if (this.validators[part]?.validate(this.data)) {
            if (autoComplete ?? true) {
                this.partComplete(part);
            }
        } else if (isCompleted) {
            this.partComplete(part);
        } else if (!isCompleted) {
            this.partIncomplete(part);
        }

        if (part === WizardPart.school || (part === WizardPart.careType && this.data.careType?.careType !== CareType.Daycare)) {
            this.onSchoolChanged();
        }

        this.changed();
    }

    public carePackageChanged(locationId: string, carePackageId: string): void {
        const key = ApplicationProvider.locationPackageKey(locationId, carePackageId);
        this.carePackageValidator.initialize(locationId, carePackageId);
        if (this.carePackageValidator.validate(this.data)) {
            this.stepsProvider.complete(key);
        } else {
            this.stepsProvider.incomplete(key);
        }

        const description = this.carePackageStepProvider.getLocationPackageDescription(locationId, carePackageId, 'short');
        this.stepsProvider.setDescription(key, description);

        this.partChanged(key);
    }

    private onWelcomeComplete(): void {
        const ageAtStart = this.data.startAt.diff(this.data.child.dateOfBirth, 'years');
        if (!this.careTypeStepProvider.autoSelectCareType(ageAtStart)) {
            this.stepsProvider.moveToStep(WizardPart.careType);
        }
    }

    private onCareTypeComplete(): void {
        this.stepsProvider.setDescription(WizardPart.careType, DirectText(this.data.careType?.name));
        if (this.careTypeStepProvider.requiresSchool()) {
            if (this.stepsProvider.findStep(WizardPart.school).index < 0) {
                this.stepsProvider.insertAfter(WizardPart.careType, Steps.school);
                this.initializeContent(this.contents);
            }
            this.stepsProvider.moveToStep(WizardPart.school);
        } else {
            this.stepsProvider.remove(WizardPart.school);
            this.onSchoolComplete();
        }
    }

    private onCareTypeIncomplete(): void {
        this.stepsProvider.setDescription(WizardPart.careType, undefined);
        this.stepsProvider.remove(WizardPart.school);
    }

    private onSchoolChanged(): void {
        this.clearLocationPackages();
    }

    private onSchoolComplete(): void {
        this.stepsProvider.setDescription(WizardPart.school, DirectText(this.data.school?.name));
        this.store.dispatch(ApplicationActions.loadLocations(
                this.data.startAt,
                this.data.school?.schoolId,
                this.data.careType.careType
        ));
        this.stepsProvider.moveToStep(WizardPart.locations);
    }

    private onLocationIncomplete(): void {
        this.clearLocationPackages();
    }

    private onLocationComplete(): void {
        this.stepsProvider.setDescription(WizardPart.locations, TranslatableText('applications.steps.locations.descriptionFormat', {count: this.data.packages.length}));
    }

    private onQuestionsComplete(): void {
        this.stepsProvider.setDescription(WizardPart.questions, TranslatableText('applications.steps.questions.descriptionFormat', {count: this.countAnswers()}));
        this.stepsProvider.moveNext();
    }

    private onQuestionsIncomplete(): void {
        this.data.answers = [];
        this.stepsProvider.setDescription(WizardPart.questions, undefined);
    }

    private onYourDataComplete(): void {
        this.stepsProvider.moveNext();
    }

    private countAnswers(): number {
        let i = 0;
        for (const answer of this.data.answers) {
            if (answer.answer != null && answer.answer !== '') {
                i++;
            }
        }
        return i;
    }

    public carePackageSelected(carePackage: LocationPackage): void {
        this.stepsProvider.insertAfter(this.lastPackageStepKey(), {
            key: WizardPart.carePackage,
            title: DirectText(carePackage.carePackageName),
            description: DirectText(''),
            subTitle: TranslatableText('applications.steps.packages.subTitle'),
            isCompleted: false,
            isActive: true,
            state: {locationId: carePackage.locationId, carePackageId: carePackage.carePackageId},
            component: <CarePackageStep/>
        });
        this.initializeContent(this.contents);

        this.store.dispatch(ApplicationActions.loadCarePackageDetails(
                carePackage.carePackageId,
                carePackage.locationId,
                this.data.school?.schoolId,
                this.data.startAt
        ));
    }

    private lastPackageStepKey(): string {
        if (this.data.packages.length > 0) {
            const p = this.data.packages[this.data.packages.length - 1];
            return ApplicationProvider.locationPackageKey(p.locationId, p.carePackageId);
        }
        return 'locations';
    }

    public carePackageDeselected(carePackage: LocationPackage): void {
        const key = ApplicationProvider.locationPackageKey(carePackage.locationId, carePackage.carePackageId);
        this.stepsProvider.remove(key);
    }

    public getStepsProvider(): StepsProvider {
        return this.stepsProvider;
    }

    public getWelcomeStepProvider(): WelcomeStepProvider {
        return this.welcomeStepProvider;
    }

    public getCareTypeStepProvider(): CareTypeStepProvider {
        return this.careTypeStepProvider;
    }

    public getSchoolStepProvider(): SchoolStepProvider {
        return this.schoolStepProvider;
    }

    public getLocationStepProvider(): LocationStepProvider {
        return this.locationStepProvider;
    }

    public getCarePackageStepProvider(): CarePackageStepProvider {
        return this.carePackageStepProvider;
    }

    public getYourDataStepProvider(): YourDataStepProvider {
        return this.yourDataStepProvider;
    }

    public getQuestionsStepProvider(): QuestionsStepProvider {
        return this.questionsStepProvider;
    }
    
    public getMode(): Mode {
        return this.data.mode;
    }

    public static locationPackageKey(locationId: string, carePackageId: string): string {
        return `carePackage|${locationId}|${carePackageId}`;
    }

    private clearLocationPackages(): void {
        for (const p of this.data.packages) {
            this.stepsProvider.remove(ApplicationProvider.locationPackageKey(p.locationId, p.carePackageId));
        }
        this.data.packages = [];
        this.stepsProvider.setDescription(WizardPart.locations, undefined);
        this.stepsProvider.incomplete(WizardPart.locations);
    }

    private changed(): void {
        for (const callback of this.changedListeners) {
            callback();
        }
    }

    public onChanged(callback: Callback): Callback {
        this.changedListeners.push(callback);

        return () => {
            this.changedListeners.splice(this.changedListeners.indexOf(callback), 1);
        };
    }
}

export function useApplicationProvider(): ApplicationProvider {
    return useContext(ApplicationContext);
}

export function useStepsProvider(): StepsProvider {
    return useContext(ApplicationContext).getStepsProvider();
}

export function useWelcomeStepProvider(): WelcomeStepProvider {
    return useContext(ApplicationContext).getWelcomeStepProvider();
}

export function useCareTypeStepProvider(): CareTypeStepProvider {
    return useContext(ApplicationContext).getCareTypeStepProvider();
}

export function useSchoolStepProvider(): SchoolStepProvider {
    return useContext(ApplicationContext).getSchoolStepProvider();
}

export function useCarePackageStepProvider(): CarePackageStepProvider {
    return useContext(ApplicationContext).getCarePackageStepProvider();
}

export function useYourDataStepProvider(): YourDataStepProvider {
    return useContext(ApplicationContext).getYourDataStepProvider();
}

export function useQuestionsStepProvider(): QuestionsStepProvider {
    return useContext(ApplicationContext).getQuestionsStepProvider();
}

export function useMode(): Mode {
    return useContext(ApplicationContext).getMode();
}

export function useStepChanged(callback: Callback): void {
    const context = useContext(ApplicationContext);
    useEffect(() => context.getStepsProvider().onChanged(callback), []);
}

export function useOnNext(callback: Callback): void {
    const context = useContext(ApplicationContext);
    useEffect(() => context.getStepsProvider().onNext(callback), []);
}

export function useApplicationChanged(callback: Callback): void {
    const context = useContext(ApplicationContext);
    useEffect(() => context.onChanged(callback), []);
}

export const ApplicationProviderComponent: React.FC = ({children}) => {
    const dispatch = useDispatch();
    const store = useStore();
    const [language] = useActiveLanguage();
    const [initialized, setInitialized] = useState(false);

    const options = useSelector(registrationOptionsRetrievedSelector);
    const questions = useSelector(questionsRetrievedSelector);
    const content = useSelector(contentRetrievedSelector);

    const provider = useMemo(() => new ApplicationProvider(store), []);
    const retrieved = useSelector(carePackageDetailsRetrievedSelector);
    const allTimeSlots = useSelector(timeSlotsSelector);

    useEffect(() => {
        if (retrieved.length > 0 && retrieved.every(x => x.status === Status.Loaded)) {
            provider.getCarePackageStepProvider().carePackageDetailsLoaded(retrieved.map(x => x.value), allTimeSlots);
        }
    }, [retrieved]);

    useEffect(() => {
        if (options.status === Status.Init) {
            dispatch(ApplicationActions.loadRegistrationOptions());
        } else if (options.status === Status.Loaded && provider != null) {
            provider.initializeOptions(options.value);
            setInitialized(true);
            if (content.status === Status.Loaded && provider != null) {
                provider.initializeContent(content.value.content);
            }
        }
    }, [options.status, provider]);

    useEffect(() => {
        if (questions.status === Status.Init) {
            dispatch(ApplicationActions.loadQuestions());
        } else if (questions.status === Status.Loaded && provider != null) {
            const remarkQuestion: QuestionModel = {
                questionId: 'remark',
                isRequired: false,
                question: 'Opmerkingen',
                questionType: QuestionType.MultilineText

            };
            questions.value.questions.push(remarkQuestion);
            provider.initializeQuestions(questions.value.questions);
        }
    }, [questions.status, provider]);

    useEffect(() => {
        if (content.status === Status.Init) {
            dispatch(ApplicationActions.loadContent(language?.code));
        } else if (content.status === Status.Loaded && provider != null) {
            provider.initializeContent(content.value.content);
        }
    }, [content.status, provider]);

    return (
            <ApplicationContext.Provider value={provider}>
                {options.status === Status.Loading && (
                        <ProgressContainer>
                            <div id="text">
                                <LoadingContainer/>
                            </div>
                        </ProgressContainer>
                )}
                {options.status === Status.Error && (
                        <ProgressContainer>
                            <div id="text">
                                <Error error={{message: options.error.message}}/>
                            </div>
                        </ProgressContainer>
                )}

                {/* eslint-disable-next-line react/prop-types */}
                {initialized && children}
            </ApplicationContext.Provider>
    );
};

const ProgressContainer = styled.div`
    display: Grid;
    grid-template-rows: 1fr max-content max-content 1fr;
    row-gap: 15px;

    justify-content: center;
    height: 100%;

    #progress {
        grid-row: 2;
        text-align: center;
    }

    #text {
        grid-row: 3;
        text-align: center;
    }
`;