import moment from 'moment';
import { CarePackageTimeslot, DayOfWeek, CarePackageDetailsResponse, TimeSlot } from '@/client/models';
import { CareTimeslot, LocationPackage } from './location-package';
import { ComposedDisplayText, ComposedText, TranslatableText } from './steps-provider';

export interface StepData {
    packages?: LocationPackage[];
    startAt?: moment.Moment;
}

export interface CarePackageStepCallback {
    carePackageChanged(locationId: string, carePackageId: string): void;
}

export class CarePackageStepProvider {
    private data: StepData;
    private callback: CarePackageStepCallback;

    constructor(data: StepData, callback: CarePackageStepCallback) {
        this.data = data;
        this.callback = callback;

        this.getLocationPackageDescription = this.getLocationPackageDescription.bind(this);
        this.isCarePackageTimeslotSelected = this.isCarePackageTimeslotSelected.bind(this);
        this.toggleCarePackageTimeslot = this.toggleCarePackageTimeslot.bind(this);
        this.carePackageDetailsLoaded = this.carePackageDetailsLoaded.bind(this);
    }

    public getLocationPackageDescription(locationId: string, carePackageId: string, display: 'short' | 'full'): ComposedDisplayText {
        const { index, carePackage } = this.findLocationPackage(locationId, carePackageId);
        if (index < 0 || carePackage.timeslots.length === 0) {
            return undefined;
        }

        const uniqueItems = Array.from(new Set(carePackage.timeslots.map(x => `global.day-of-week.${display}.${x.day}`)));

        return ComposedText(uniqueItems.map(x => TranslatableText(x)));
    }

    public isCarePackageTimeslotSelected(locationId: string, carePackageId: string, timeslot: CarePackageTimeslot): boolean {
        const { index } = this.findCarePackageTimeslot(locationId, carePackageId, timeslot);
        return index >= 0;
    }

    public toggleCarePackageTimeslot(locationId: string, carePackageId: string, timeslot: CarePackageTimeslot, day: DayOfWeek): void {
        const { index, carePackage } = this.findCarePackageTimeslot(locationId, carePackageId, timeslot);
        if (carePackage == null) {
            return;
        }
        if (index >= 0) {
            carePackage.timeslots.splice(index, 1);
        } else {
            this.addCarePackageTimeslot(carePackage, timeslot, day);
        }

        this.callback.carePackageChanged(locationId, carePackageId);
    }

    public carePackageDetailsLoaded(carePackageResponses: CarePackageDetailsResponse[], allTimeSlots: TimeSlot[]): void {
        for (const current of this.data.packages) {
            const response = carePackageResponses.find(x => x.carePackageId === current.carePackageId);
            let changed = false;

            for (const timeslot of response.timeslots) {
                const { index, carePackage } = this.findCarePackageTimeslot(response.locationId, response.carePackageId, timeslot);

                const timeSlot = allTimeSlots.find(x => x.timeSlotId === timeslot.timeslotId);
                if (index < 0 && carePackage != null) {
                    if (timeslot.alwaysSelected) {
                        this.addCarePackageTimeslot(current, timeslot, timeSlot.dayOfWeek);
                        changed = true;
                    }
                }
            }
            if (changed) {
                this.callback.carePackageChanged(response.locationId, response.carePackageId);
            }
        }
    }

    private addCarePackageTimeslot(carePackage: LocationPackage, timeslot: CarePackageTimeslot, day: DayOfWeek): void {
        carePackage.timeslots.push({
            carePackageId: timeslot.carePackageId,
            timeslotId: timeslot.timeslotId,
            day: day,
            start: timeslot.start,
            end: timeslot.end,
            extended: timeslot.extended,
            from: this.data.startAt
        });
    }

    private findCarePackageTimeslot(locationId: string, carePackageId: string, timeslot: CarePackageTimeslot): { index: number; careTimeslot: CareTimeslot; carePackage: LocationPackage } {
        const { carePackage } = this.findLocationPackage(locationId, carePackageId);
        if (carePackage == null) {
            return { index: -1, careTimeslot: undefined, carePackage };
        }
        const index = carePackage.timeslots.findIndex(x => x.timeslotId === timeslot.timeslotId && timeslot.extended === x.extended);
        const careTimeslot = index >= 0 ? carePackage.timeslots[index] : undefined;
        return { index, careTimeslot, carePackage };
    }

    private findLocationPackage(locationId: string, carePackageId: string): { index: number; carePackage: LocationPackage } {
        const index = this.data.packages.findIndex(x => x.locationId === locationId && x.carePackageId === carePackageId);
        const carePackage = index >= 0 ? this.data.packages[index] : undefined;
        return { index, carePackage };
    }
}
