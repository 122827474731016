import {useApplicationProvider} from '@/applications/application-provider';
import {NetworkUtils} from '@/networking/network-utils';
import qs from 'qs';
import React from 'react';
import {Translate} from 'react-localize-redux';
import styled from 'styled-components';
import {Button} from 'antd';
import {useTenant} from '@/tenant/tenant-provider';
import {Mode} from '@/applications/application-data';


export const FinishedScreen: React.FC = () => {
    const provider = useApplicationProvider();

    const { tenant } = useTenant();

    const develop = NetworkUtils.isDevelop();

    const parsed = qs.parse(globalThis.window.location.search.slice(1));
    const lang = parsed['lang']?.toString();
    const country = parsed['country']?.toString();

    const secure = window.location.href.startsWith('https');
    const host = `${(secure ? 'https' : 'http')}://${window.location.host}/`;
    
    const data = provider.getData();    
    const applicationId = provider.getMode() === Mode.new ? data.applicationId : data.existingApplicationId;

    const querystring = qs.stringify({
        tenant,
        develop: develop ? true : undefined,
        lang,
        country,
        applicationId
    });

    const secondChildLink = `${host}?${querystring}`;
    const backLink = `https://${tenant}.kindplanner.nl`;

    return (
        <Container>
            <Content>
                <h1><Translate id='applications.steps.finished.title' /></h1>
                <h2><Translate id='applications.steps.finished.subtitle' /></h2>

                <ButtonsContainer>
                    <ThemedButton type="primary" size='large' href={backLink}>
                        <Translate id='applications.steps.finished.back-to-portal' />
                    </ThemedButton>
                    <ThemedButton type="primary" size='large' href={secondChildLink}>
                        <Translate id='applications.steps.finished.next-child' />
                    </ThemedButton>
                </ButtonsContainer>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 25px;
`;

const ThemedButton = styled(Button)`
    background-color: ${x => x.theme.primary};
    border-color: ${x => x.theme.primary};
`;

const Content = styled.div`
    > h1 {
        text-align: center;
    }

    > h2 {
        text-align: center;
    }
`;