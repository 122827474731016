import qs from 'qs';

export class NetworkUtils {
    public static getTenantFromUrl(): string | null {
        const parsed = qs.parse(globalThis.window.location.search.slice(1));
        if (parsed['tenant'] != null) {
            console.log('Found in query: ' + parsed['tenant']);
            return parsed['tenant'].toString();
        }

        return null;
    }

    public static createBackOfficeApiUrl(tenant: string): string {
        return `https://backoffice-${tenant}.kindplanner.nl`;
    }

    public static isDevelop(): boolean {
        const parsed = qs.parse(globalThis.window.location.search);

        return parsed['develop'] != null;
    }

    public static getApplicationId(): string {
        const parsed = qs.parse(globalThis.window.location.search);
        return parsed['applicationId']?.toString();
    }
}
