import { WizardPart } from './wizard-part';
import { ProgressReporter } from './progress-reporter';
import { School } from '@/client/models';

export interface StepData {
    school?: School;
}

export class SchoolStepProvider {
    private data: StepData;
    private reporter: ProgressReporter;
    private schools: School[];

    constructor(data: StepData, reporter: ProgressReporter) {
        this.data = data;
        this.reporter = reporter;
        this.initialize = this.initialize.bind(this);
        this.getSchools = this.getSchools.bind(this);
        this.isSchoolSelected = this.isSchoolSelected.bind(this);
        this.toggleSchool = this.toggleSchool.bind(this);
        this.getSelectedSchoolId = this.getSelectedSchoolId.bind(this);
    }

    public initialize(schools: School[]): void {
        if (schools == null) {
            schools = undefined;
        }
        this.schools = schools.sort((a, b) => a.name.localeCompare(b.name));
    }

    public getSchools(): School[] {
        return this.schools;
    }

    public isSchoolSelected(school: School): boolean {
        return this.data.school?.schoolId === school.schoolId;
    }

    public toggleSchool(school: School): void {
        if (this.isSchoolSelected(school)) {
            this.setSchool(undefined);
        } else {
            this.setSchool(school);
        }
    }

    public getSelectedSchoolId(): string {
        return this.data.school?.schoolId;
    }

    private setSchool(school: School): void {
        this.data.school = school;
        this.reporter.partChanged(WizardPart.school);
    }
}