import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApplicationProvider, useStepsProvider, useCarePackageStepProvider, useApplicationChanged, useStepChanged } from '@/applications/application-provider';
import { CarePackageDetailsResponse, CarePackageTimeslot } from '@/client/models';
import { LoadingContainer } from '@/components/loading-container';
import { carePackageDetailsRetrievedSelector, locationsSelector } from '@/store/application/selectors';
import { Status } from '@/store/status';
import { timeSlotsSelector } from '@/store/general/selectors';
import { Switch, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import { RetrievedValue } from '@/store/retrieved-value';
import { formatTime } from '@/momentjs/duration-helper';
import { Location } from '@/client/models';
import { ToText } from '@/applications/steps-provider';
import { useTranslate } from '@/language-provider';
import styled from 'styled-components';

export const CarePackageStep: React.FC = () => {
    const applicationProvider = useApplicationProvider();
    const stepsProvider = useStepsProvider();
    const locations = useSelector(locationsSelector);
    const [locationId, setLocationId] = useState(stepsProvider.getCurrentStep().state.locationId);
    const [carePackageId, setCarePackageId] = useState(stepsProvider.getCurrentStep().state.carePackageId);
    const schoolId = applicationProvider.getSchoolStepProvider().getSelectedSchoolId();
    const provider = applicationProvider.getCarePackageStepProvider();
    const { translate } = useTranslate();

    const retrieved = useSelector(carePackageDetailsRetrievedSelector);
    const [carePackageRetrieved, setCarePackage] = useState<RetrievedValue<CarePackageDetailsResponse>>();
    const [description, setDescription] = useState<string>();

    const findLocation = (locationId: string): Location => locations.find(x => x.locationId === locationId);

    useApplicationChanged(() => setDescription(ToText(translate, provider.getLocationPackageDescription(locationId, carePackageId, 'full'))));

    useStepChanged(() => {
        const state = stepsProvider.getCurrentStep().state;
        if (state != null) {
            setLocationId(state.locationId);
            setCarePackageId(state.carePackageId);
        }
    });

    useEffect(() => {
        const createKey = (item: CarePackageDetailsResponse): string => `${item.carePackageId}:${item.locationId}:${item.schoolId}`;
        const carePackageResponse = retrieved.find(x => createKey(x.value) === `${carePackageId}:${locationId}:${schoolId}`);

        setCarePackage(carePackageResponse);
        setDescription(ToText(translate, provider.getLocationPackageDescription(locationId, carePackageId, 'full')));
    }, [carePackageRetrieved?.status, carePackageId, locationId, schoolId, retrieved]);

    if (carePackageRetrieved?.status !== Status.Loaded) {
        return <LoadingContainer />;
    }

    const timeslots = carePackageRetrieved.value.timeslots;
    const createRowKey = (timeslot: CarePackageTimeslot): string => `${carePackageId}:${locationId}:${schoolId}:${timeslot.timeslotId}:${timeslot.extended}`;

    return (<>
        <Title level={3}>{findLocation(locationId).name} <small>{description}</small></Title>
        <Divider />
        <Container>
            {timeslots.map((x, row) => {
                return <CarePackageTimeslotComponent key={createRowKey(x)} row={row} locationId={locationId} carePackageId={carePackageId} careTimeslot={x} />;
            })}
        </Container>
    </>);
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
`;

const CarePackageTimeslotComponent: React.FC<{ row: number; locationId: string; carePackageId: string; careTimeslot: CarePackageTimeslot }> = ({ locationId, carePackageId, careTimeslot }) => {
    const timeslots = useSelector(timeSlotsSelector);
    const provider = useCarePackageStepProvider();
    const timeslot = timeslots.find(x => x.timeSlotId === careTimeslot.timeslotId);

    const [isSelected, setIsSelected] = useState(() => provider.isCarePackageTimeslotSelected(locationId, carePackageId, careTimeslot));

    useApplicationChanged(() => {
        setIsSelected(provider.isCarePackageTimeslotSelected(locationId, carePackageId, careTimeslot));
    });

    const onChange = (): void => provider.toggleCarePackageTimeslot(locationId, carePackageId, careTimeslot, timeslot.dayOfWeek);
    const formatPrice = (price?: number): string => {
        if (price == null) {
            return;
        }
        return price.toLocaleString(navigator.language, { minimumFractionDigits: 2 });
    };

    return (
        <TimeContainer>
            <div className="switch"><Switch size='small' checked={isSelected} disabled={careTimeslot.alwaysSelected} onChange={onChange} /></div>
            <div className="item">{timeslot?.name + (careTimeslot.extended ? ' verlengd' : '')}</div>
            <div className="item">{formatTime(careTimeslot.start)} - {formatTime(careTimeslot.end)}</div>
            <div className="item" style={{ textAlign: 'right' }}> {formatPrice(careTimeslot.price)}</div>
        </TimeContainer>
    );
};

const TimeContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 5px;

    .item {
        flex: 1;
        min-width: 100px;
    }
`;