import React, { useState } from 'react';
import { InfoCard } from '@/components/info-card';
import { HomeOutlined } from '@ant-design/icons';
import { School, Address } from '@/client/models';
import { GridLayout, GridLayoutItem } from '@/components/grid-layout';
import { useSchoolStepProvider, useApplicationProvider } from '@/applications/application-provider';
import { SchoolStepProvider } from '@/applications/school-step-provider';

function address(school: School): Address {
    return school.addresses[0];
}
function addressLine1(school: School): string {
    const a = address(school);
    return `${a.street} ${a.number ?? ''}${a.extension ?? ''}`;
}
function addressLine2(school: School): string {
    const a = address(school);
    return `${a.zipCode} ${a.city}`;
}

const SchoolComponent: React.FC<{ school: School; provider: SchoolStepProvider }> = ({ school, provider }) => {
    const [isSelected, setIsSelected] = useState(provider.isSchoolSelected(school));
    const applicationProvider = useApplicationProvider();
    applicationProvider.onChanged(() => setIsSelected(provider.isSchoolSelected(school)));

    return (
        <InfoCard
            icon={<HomeOutlined />}
            imageUrl={school.imageUrl}
            title={school.name}
            lines={[addressLine1(school), addressLine2(school)]}
            onClick={() => provider.toggleSchool(school)} isSelected={isSelected} />
    );
};

export const SchoolStep: React.FC = () => {
    const provider = useSchoolStepProvider();
    const schools = provider.getSchools();
    const rows = schools.length / 3;

    return (
        <GridLayout columns={3} rows={rows} rowgap="16px" colgap="16px">
            {schools.map((x, i) => (
                <GridLayoutItem key={x.schoolId} column={i / rows + 1} row={i % rows + 1}>
                    <SchoolComponent school={x} provider={provider} />
                </GridLayoutItem>
            ))}
        </GridLayout>
    );
};