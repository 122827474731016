import moment from 'moment';
import { WizardPart } from './wizard-part';
import { ProgressReporter } from './progress-reporter';
import { PersonData, ContactInfoData } from './person-data';
import { Child, Gender } from '@/client/models';
import { DisplayText, TranslatableText } from './steps-provider';

export interface StepData {
    child?: Child;
    primaryContact?: PersonData & ContactInfoData;
    partner?: PersonData & ContactInfoData;
    street?: string;
    number?: string;
    numberExtension?: string;
    city?: string;
    zipCode?: string;
}

export type GenderSelection = {
    label: DisplayText;
    value?: Gender;
};

export class YourDataStepProvider {
    private readonly data: StepData;
    private reporter: ProgressReporter;
    private readonly genders: GenderSelection[];
    private readonly childGenders: GenderSelection[];

    constructor(data: StepData, reporter: ProgressReporter) {
        this.data = data;
        this.reporter = reporter;
        this.initialize = this.initialize.bind(this);
        this.getFormData = this.getFormData.bind(this);
        this.setFormData = this.setFormData.bind(this);
        this.isFirstNameRequired = this.isFirstNameRequired.bind(this);
        this.setDateOfBirth = this.setDateOfBirth.bind(this);
        this.next = this.next.bind(this);
        this.genders = [
            { label: TranslatableText('global.gender.notSet') },
            { label: TranslatableText('global.gender.male'), value: Gender.Male },
            { label: TranslatableText('global.gender.female'), value: Gender.Female }
        ];
        this.childGenders = [
            { label: TranslatableText('global.childGender.notSet') },
            { label: TranslatableText('global.childGender.male'), value: Gender.Male },
            { label: TranslatableText('global.childGender.female'), value: Gender.Female }
        ];
    }

    public initialize(): void {
        if (this.data.child == null) {
            this.data.child = { gender: undefined, dateOfBirth: undefined };
        }
    }

    public getGenders(isRequired?: boolean, isChildGender?: boolean): GenderSelection[] {
        let genders = this.genders;
        if (isChildGender ?? false) {
            genders = this.childGenders;
        }
        if (isRequired ?? false) {
            return [genders[1], genders[2]];
        }
        return genders;
    }

    public getFormData(): StepData {
        return { ...this.data };
    }

    public setFormData(data: StepData): void {
        this.data.street = data.street;
        this.data.number = data.number;
        this.data.numberExtension = data.numberExtension;
        this.data.zipCode = data.zipCode;
        this.data.city = data.city;
        this.data.child = data.child;
        
        if (data.primaryContact != null) {
            this.data.primaryContact = YourDataStepProvider.copyPerson(data.primaryContact);
        }
        
        if (data.partner != null) {
            this.data.partner = YourDataStepProvider.copyPerson(data.partner);
        }
        this.changed();
    }

    private static copyPerson(from?: PersonData & ContactInfoData): PersonData & ContactInfoData {
        return { ...from, dateOfBirth: from.dateOfBirth?.startOf('day') };
    }

    public setDateOfBirth(date: moment.Moment): void {
        this.data.child.dateOfBirth = date?.startOf('day');
        this.changed();
    }

    public isFirstNameRequired(): boolean {
        if (this.data.child.dateOfBirth == null) {
            return false;
        }

        return this.data.child.dateOfBirth.isBefore(moment.now());
    }

    public next(): void {
        this.reporter.partChanged(WizardPart.yourData);
    }

    private changed(): void {
        this.reporter.partChanged(WizardPart.yourData, false);
    }
}
