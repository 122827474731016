import React from 'react';
import { Alert, Modal } from 'antd';
import { useTranslate } from './language-provider';
import { Button } from './components/button';
import styled from 'styled-components';

export function Error({ error }: { error: { message?: string; stack?: string } }): React.ReactElement {
    const { translate } = useTranslate();

    function showDetails(): React.ReactNode {
        return Modal.error({
            width: '1200px',
            title: translate('global.generic.error'),
            content: (
                <div>
                    <p>{error.message}</p>
                    <pre>{error.stack}</pre>
                </div>
            )
        });
    }

    return (
        <ErrorContainer>
            <Alert type="error" showIcon
                message={translate('global.generic.unexpected-error-title')}
                description={
                    <div>
                        <p>{translate('global.generic.unexpected-error-message')}</p>
                        <Button translation='global.generic.details' onClick={() => showDetails()} />
                    </div>
                }
            />
        </ErrorContainer>
    );
}


const ErrorContainer = styled.div`
    margin-top: 15px;
`;