import { UsersApi, AuthApi, LocationsApi, TimeslotsApi, OptionsApi, QuestionsApi, ContentApi, ApplicationsApi } from '@/client/api';
import { getEnvironment } from '@/environment-mode';

export interface Dependencies {
    authApi: AuthApi;
    usersApi: UsersApi;
    locationsApi: LocationsApi;
    timeSlotsApi: TimeslotsApi;
    optionsApi: OptionsApi;
    questionsApi: QuestionsApi;
    contentApi: ContentApi;
    applicationsApi: ApplicationsApi;
}

const { apiUrl } = getEnvironment();

export const createDependencies = (): Dependencies => {
    return {
        authApi: new AuthApi(apiUrl),
        usersApi: new UsersApi(apiUrl),
        locationsApi: new LocationsApi(apiUrl),
        timeSlotsApi: new TimeslotsApi(apiUrl),
        optionsApi: new OptionsApi(apiUrl),
        questionsApi: new QuestionsApi(apiUrl),
        contentApi: new ContentApi(apiUrl),
        applicationsApi: new ApplicationsApi(apiUrl)
    };
};