import { NewApplicationCommand, onDeserializeNewApplicationCommand, onSerializeNewApplicationCommand } from '../models/new-application-command';
import { AddChildCommand, onDeserializeAddChildCommand, onSerializeAddChildCommand } from '../models/add-child-command';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class ApplicationsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async post(data?: NewApplicationCommand, headers?: AxiosHeaders): Promise<AxiosResponse<NewApplicationCommand>> {
        const request = {
            url: this.baseUrl + '/applications',
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeNewApplicationCommand(data);

        const response = await globalAxios.request<NewApplicationCommand>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeNewApplicationCommand(response.data);
        }

        return response;
    }

    public async postAddChild(data?: AddChildCommand, headers?: AxiosHeaders): Promise<AxiosResponse<void>> {
        const request = {
            url: this.baseUrl + '/applications/addChild',
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = onSerializeAddChildCommand(data);

        const response = await globalAxios.request<void>(request);
        

        return response;
    }
}