import React, {useEffect, useMemo} from 'react';
import {Duration} from 'moment';
import {useApplicationProvider, useMode, useOnNext} from '@/applications/application-provider';
import {notification, Spin} from 'antd';
import {useTranslate} from '@/language-provider';
import {PersonData, PhoneData} from '@/applications/person-data';
import {Gender} from '@/client/models';
import {useSelector} from 'react-redux';
import {timeSlotsSelector} from '@/store/general/selectors';
import {formatTime} from '@/momentjs/duration-helper';
import {QuestionGroup} from '@/applications/questions-step-provider';
import {ToText} from '@/applications/steps-provider';
import {WizardPart} from '@/applications/wizard-part';
import {countriesSelector, locationsSelector, submitFormSelector} from '@/store/application/selectors';
import {Status} from '@/store/status';
import {ApplicationDataConverter} from '@/applications/application-data-converter';
import styled from 'styled-components';
import {Mode} from '@/applications/application-data';

const getFullName = (person: PersonData): string => {
    if (person == null) {
        return '';
    }

    let fullName = '';
    if (person.firstName != null) {
        fullName += person.firstName;
    }
    if (person.middleName != null) {
        fullName += ` ${person.middleName}`;
    }
    if (person.lastName != null) {
        fullName += ` ${person.lastName}`;
    }
    return fullName.trim();
};

export const SummaryStep: React.FC = () => {
    const {translate} = useTranslate();
    const {status, error} = useSelector(submitFormSelector);
    const provider = useApplicationProvider();
    const data = provider.getData();
    const yourData = provider.getYourDataStepProvider();
    const countries = useSelector(countriesSelector);
    const mode = useMode();

    useEffect(() => {
        const command = new ApplicationDataConverter().toNewCommand(data);
        console.log(command);
    }, [data]);

    useEffect(() => {
        if (status === Status.Error && error != null && !error.hasBeenShown) {
            notification['error']({
                message: translate('global.generic.error'),
                description: error.description
            });
            error.hasBeenShown = true;
        }
    }, [status, error]);

    useOnNext(() => provider.partComplete(WizardPart.summary));

    const getPhoneNumber = (phoneData: PhoneData): string => {
        let phoneNumber = '+' + phoneData.code;
        if (phoneData.phone.startsWith('0')) {
            phoneNumber += phoneData.phone.substring(1);
        } else {
            phoneNumber += phoneData.phone;
        }
        return phoneNumber;
    };

    const gender = (gender?: Gender, isChildGender?: boolean): string => {
        return ToText(translate, yourData.getGenders(false, isChildGender).find(g => g.value === gender)?.label);
    };

    const translateData = (id: string): string => translate(`applications.data.${id}`).toString();

    return (
            <Spin tip={`${translate('global.saving')}...`} spinning={status === Status.Loading}>
                <SummaryContainer>
                    <h1>{translate('summary.your-request-title', {careType: translate(`global.careType.${data.careType.careType}`), startAt: data.startAt.format('L')})}</h1>
                    <h2>{translate('summary.your-request-subtitle', {name: getFullName(data.child)})}</h2>

                    <FlexDataContainer>
                        {data.packages.map(p => {
                            return (
                                    <div key={p.carePackageId}>
                                        <PackageTitle locationId={p.locationId} packageId={p.carePackageId}/>
                                        <table>
                                            {p.timeslots.map(t => <TimeSlotItem key={`${p.carePackageName}-${t.timeslotId}`} timeslotId={t.timeslotId} start={t.start}
                                                                                end={t.end}/>)}
                                        </table>
                                    </div>
                            );
                        })}
                    </FlexDataContainer>
                    <FlexDataContainer>
                        <div>
                            <h1>{translate('summary.data-from', {name: getFullName(data.child)})}</h1>
                            <table>
                                <tr>
                                    <td>{translateData('person.fullName')}</td>
                                    <td>{getFullName(data.child)}</td>
                                </tr>
                                <tr>
                                    <td>{translate('applications.data.person.expectedDateOfBirth').toString()}</td>
                                    <td>{data.child.dateOfBirth?.format('L')}</td>
                                </tr>
                                <tr>
                                    <td>{translateData('person.gender')}</td>
                                    <td>{gender(data.child.gender, true)}</td>
                                </tr>
                                {data.school != null && <tr>
                                    <td>{translateData('school')}</td>
                                    <td>{data.school.name}</td>
                                </tr>}
                                <tr>
                                    <td>{translateData('emergencyNumber')}</td>
                                    <td>{getPhoneNumber(data.child.emergencyNumber)}</td>
                                </tr>
                            </table>
                        </div>
                        
                        {mode === Mode.new && 
                        <>
                        <div>
                            <h1>{translate('summary.your-data')}</h1>
                            <table>
                                <tr>
                                    <td>{translateData('person.fullName')}</td>
                                    <td>{getFullName(data.primaryContact)}</td>
                                </tr>
                                {data.primaryContact.dateOfBirth != null && <tr>
                                    <td>{translateData('person.dateOfBirth')}</td>
                                    <td>{data.primaryContact.dateOfBirth?.format('L')}</td>
                                </tr>}
                                <tr>
                                    <td>{translateData('person.gender')}</td>
                                    <td>{gender(data.primaryContact.gender, false)}</td>
                                </tr>
                                {data.primaryContact.email != null && <tr>
                                    <td>{translateData('person.email')}</td>
                                    <td>{data.primaryContact.email}</td>
                                </tr>}
                                {data.primaryContact.phone != null && <tr>
                                    <td>{translateData('person.phone')}</td>
                                    <td>{getPhoneNumber(data.primaryContact.phone)}</td>
                                </tr>}
                                {data.country != null && <tr>
                                    <td>{translateData('country')}</td>
                                    <td>{countries.find(x => x.countryId === data.country)?.name}</td>
                                </tr>}
                                {data.zipCode && data.zipCode !== '' &&
                                    <tr>
                                    <td>{translateData('zipCode')}</td>
                                    <td>{data.zipCode}</td>
                                </tr>
                                }
                                <tr>
                                    <td>{translateData('street')}</td>
                                    <td>{data.street}</td>
                                </tr>
                                <tr>
                                    <td>{translateData('number')}</td>
                                    <td>{data.number}{data.numberExtension}</td>
                                </tr>
                                <tr>
                                    <td>{translateData('city')}</td>
                                    <td>{data.city}</td>
                                </tr>
                            </table>
                        </div>

                        {data.partner?.lastName != null &&
                        <div>
                            <h1>{translate('summary.partner-data')}</h1>
                            <table>
                                <tr>
                                    <td>{translateData('person.fullName')}</td>
                                    <td>{getFullName(data.partner)}</td>
                                </tr>
                                {data.partner.dateOfBirth != null && <tr>
                                    <td>{translateData('person.dateOfBirth')}</td>
                                    <td>{data.partner.dateOfBirth?.format('L')}</td>
                                </tr>}
                                <tr>
                                    <td>{translateData('person.gender')}</td>
                                    <td>{gender(data.partner.gender, false)}</td>
                                </tr>
                                {data.partner.email != null && <tr>
                                    <td>{translateData('person.email')}</td>
                                    <td>{data.partner.email}</td>
                                </tr>}
                                {data.partner.phone != null && <tr>
                                    <td>{translateData('person.phone')}</td>
                                    <td>{getPhoneNumber(data.partner.phone)}</td>
                                </tr>}
                            </table>
                        </div>
                        }
                    </>}
                    </FlexDataContainer>

                    <FlexDataContainer>
                        {provider.getQuestionsStepProvider().getQuestionGroups().map(x => <QuestionGroupItem key={x.carePackageId} group={x}/>)}
                    </FlexDataContainer>
                </SummaryContainer>
            </Spin>
    );
};

const PackageTitle: React.FC<{ locationId: string; packageId: string }> = ({locationId, packageId}) => {
    const locations = useSelector(locationsSelector);
    const location = useMemo(() => locations.find(t => t.locationId === locationId), [locationId, locations]);
    const carePackage = useMemo(() => location.carePackages.find(x => x.carePackageId === packageId), [packageId, location]);

    return <h1>{carePackage?.name}&nbsp;({location?.name})</h1>;
};

const TimeSlotItem: React.FC<{ timeslotId: string; start: Duration; end: Duration }> = ({timeslotId, start, end}) => {
    const timeslots = useSelector(timeSlotsSelector);
    const timeslot = useMemo(() => timeslots.find(t => t.timeSlotId === timeslotId), [timeslotId, timeslots]);

    return (
            <tr>
                <td>{timeslot?.name}</td>
                <td>{formatTime(start)} - {formatTime(end)}</td>
            </tr>
    );
};

const QuestionGroupItem: React.FC<{ group: QuestionGroup }> = ({group}) => {
    const {translate} = useTranslate();
    const provider = useApplicationProvider();
    const data = provider.getData();

    return (
            <div>
                <h1>{ToText(translate, group.title)}</h1>
                <table>
                    {group.questions.map(x => <tr key={x.questionId}>
                        <td>{x.question}</td>
                        <td>{data.answers.find(y => y.questionId === x.questionId)?.answer}</td>
                    </tr>)}
                </table>
            </div>
    );
};

const FlexDataContainer = styled.div`
    display: flex;
    column-gap: 50px;
    margin-top: 25px;
`;

const SummaryContainer = styled.div`
    tr td:first-child {
        padding-right: 15px;
    }

    > div:last-child {
        margin-bottom: 25px;
    }
`;