import React from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';

export const LoadingContainer: React.FC = () => {
    const theme = useTheme();
    return (
        <div style={{ textAlign: 'center' }}>
            <Loading3QuartersOutlined style={{ fontSize: '128px', color: theme.primary }} spin />
        </div>
    );
};