import { CareType } from './care-type';
import moment from 'moment';

export interface CarePackageTimeslot {
    carePackageId: string;
    careType: CareType;
    careTimeId?: string;
    timeslotId: string;
    start?: moment.Duration;
    end?: moment.Duration;
    alwaysSelected: boolean;
    allowCustomTimes: boolean;
    extended: boolean;
    price?: number;
}

export function onDeserializeCarePackageTimeslot(data: CarePackageTimeslot): CarePackageTimeslot {
    if (data == null) {
        return data;
    }

    if (data.start != null) {
        data.start = moment.duration(data.start);
    }
    if (data.end != null) {
        data.end = moment.duration(data.end);
    }

    return data;
}

export function onSerializeCarePackageTimeslot(data: CarePackageTimeslot): CarePackageTimeslot {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };


    return serialized;
}
