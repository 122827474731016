import React, {useEffect, useMemo, useState} from 'react';
import {useApplicationChanged, useMode, useOnNext, useWelcomeStepProvider} from '@/applications/application-provider';
import {AutoComplete, Form, Input, Select} from 'antd';
import {DateInput, FormItem, FormUtilities} from '../components/form-item';
import {useTranslate} from '@/language-provider';
import Title from 'antd/lib/typography/Title';
import {AddressApi} from '@/client/api';
import {getEnvironment} from '@/environment-mode';
import styled from 'styled-components';
import {Mode} from '@/applications/application-data';
import { SelectValue } from 'antd/lib/select';

export const WelcomeStep: React.FC = () => {
    const provider = useWelcomeStepProvider();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(provider.getFormData());
    const addressApi = useMemo(() => new AddressApi(getEnvironment().apiUrl), []);
    const [suggestions, setSuggestions] = useState<{ value: string }[]>([]);
    const [address, setAddress] = useState<string>();
    const [pcOrNumberError, setPcnumberError] = useState<boolean>();
    const mode = useMode();

    const refreshAddress = () : void => {
        if (address) {
            return;
        }
        const formValues = form.getFieldsValue();
        const postalCode = formValues.zipCode;
        if (!postalCode) {
            return;
        }
        const number = formValues.number;

        if (!number) {
            return;
        }

        addressApi.getSuggestion(postalCode, number).then(result => {
            provider.setAddress(result.data.settlement, result.data.street);
            setAddress(`${result.data.settlement}, ${result.data.street}`);
        }).catch(() => { /* ignored */ });
    };

    useOnNext(() => form.submit());

    form.setFieldsValue(FormUtilities.flattenObject(formData));
    

    const [isFirstNameRequired, setIsFirstNameRequired] = useState(provider.isFirstNameRequired());
    const [selectedCountry, setSelectedCountry] = useState(provider.getSelectedCountry());

    const countries = provider.getCountries();
    const municipalities = provider.getMunicipalities();
    const { translate } = useTranslate();

    useApplicationChanged(() => {
        setIsFirstNameRequired(provider.isFirstNameRequired());
        setSelectedCountry(provider.getSelectedCountry());

        const data = provider.getFormData();
        form.setFieldsValue(FormUtilities.flattenObject(data));
        setFormData(data);

    });

    useEffect(() => {
        if (countries.length === 1 && provider.getSelectedCountry() == null) {
            provider.setCountry(countries[0].countryId);
        } else if (countries.length > 1) {
            let doSet = false;
            for (let i = 0; i < countries.length; i++) {
                const c = countries[i];
                if (c.default) {
                    provider.setCountry(c.countryId);
                    doSet = true;
                    break;
                }
            }
            if (!doSet) {
                provider.setCountry(countries[0].countryId);
            }
        }

        if (municipalities.length === 1 && provider.getSelectedMunicipality() == null) {
            provider.setMunicipality(municipalities[0].municipalityId);
        }
        refreshAddress();
    }, [countries, municipalities]);

    const postalCodeDisplay = (postalCode: string, postalCodeFormat: string): string => {
        if (!postalCodeFormat) {
            return postalCode;
        }
        const index = postalCodeFormat.indexOf(' ');
        if (index === -1) {
            return postalCode;
        }
        const partOne = postalCode.substr(0, index);
        const partTwo = postalCode.substr(index);

        return partOne + ' ' + partTwo;
    };

    const onFinish = (): void => {

        const vals = form.getFieldsValue();
        if (!hasZipCodePattern()) {
            vals.zipCode = '';
            provider.setAddress(vals.city, vals.street, '');
        }
        

        provider.setFormData(FormUtilities.rebuildObject(vals));

        if (hasZipCodePattern()) {
            autoComplete();

            if (mode === Mode.new && (!address || address === '')) {
                const formValues = form.getFieldsValue();
                const postalCode = formValues.zipCode;
                const number = formValues.number;
                if (postalCode !== '' && number !== '') {
                    setPcnumberError(true);
                    return;
                }
                setPcnumberError(false);
            }
        } 
        
        provider.next();
    };

    const onChange = (): void => {
        provider.setFormData(FormUtilities.rebuildObject(form.getFieldsValue()));
    };

    const autoComplete = (postalCode: string = undefined, number: string = undefined): boolean => {
        if (postalCode) {
            form.setFieldsValue({ zipCode: postalCode });
        }
        if (number) {
            form.setFieldsValue({ number: number });
        }

        const formValues = form.getFieldsValue();
        if (!postalCode) {
            postalCode = formValues.zipCode;
        }

        if (!number) {
            number = formValues.number;
        }

        if (!postalCode || !number) {
            return true;
        }

        addressApi.getSuggestion(postalCode, number).then(result => {
            provider.setAddress(result.data.settlement, result.data.street);
            setAddress(`${result.data.settlement}, ${result.data.street}`);
            setTimeout(() => {
                form.setFieldsValue({ zipCode: postalCode });
                form.setFieldsValue({ number: number});
            }, 100);
            setPcnumberError(false);
        }).catch(() => {
             setAddress('');
             setTimeout(() => {
                form.setFieldsValue({ zipCode: postalCode });
                form.setFieldsValue({ number: number});
            }, 100);
        });

        return true;
    };

    
    const onSearch = (searchText: string): void => {
        
        form.setFieldsValue({ zipCode: searchText });
        if (!selectedCountry?.postcodeFormat) {
            return;
        }
        const searchTextWoSpace = searchText.replace(/\s/g, '');
        addressApi.getPostalCode(searchTextWoSpace).then(result => {
             const suggestionsDisplay = result.data.results.map(x => ({ value: postalCodeDisplay(x.postalCode, selectedCountry?.postcodeFormat)}));
            setSuggestions(suggestionsDisplay);
            if (result.data.results.length === 1) {
                const { number } = form.getFieldsValue();
                if (number != null) {
                    const displayPostalCode = postalCodeDisplay(result.data.results[0].postalCode, selectedCountry?.postcodeFormat);
                    autoComplete(displayPostalCode, number);
                }
            }
        }).catch(() => { /* ignored */ });
    };

    const handleCountryChange = (e: SelectValue): void =>{
        provider.setCountry(e.toString());
        setSuggestions([]);
        setAddress('');
        setPcnumberError(false);
    };

    const hasZipCodePattern = (): boolean => {
        if (selectedCountry && selectedCountry.postcodePattern) {
            return selectedCountry.postcodePattern !== null && selectedCountry.postcodePattern !== '';
        }
        return false;
    };

    return (
            <Container form={form} layout='vertical' onFinish={onFinish} onFinishFailed={onChange}>
                <div>
                    <Title level={3}>{translate('applications.steps.yourData.childTitle')}</Title>
                    <FormItem name='child.dateOfBirth' text={translate('applications.data.person.expectedDateOfBirth').toString()} required={true}>
                        <DateInput onChange={provider.setDateOfBirth} />
                    </FormItem>
                    <FormItem name='child.firstName' required={isFirstNameRequired}>
                        <Input />
                    </FormItem>
                </div>
                {mode === Mode.new ? (
                        <div>
                            <Title level={3}>{translate('applications.steps.yourData.title')}</Title>
                            {countries.length > 1 &&
                            <FormItem name='country' required={true}>
                                <Select onChange={e => handleCountryChange(e)}>
                                    {countries.map(x => <Select.Option key={x.countryId} value={x.countryId}>{x.name}</Select.Option>)}
                                </Select>
                            </FormItem>
                            }
                            {hasZipCodePattern() &&
                            <FormItem label={translate('applications.data.zipCode')} name='zipCode' required={hasZipCodePattern()} regex={selectedCountry?.postcodePattern} regexMessage='applications.errors.invalidZipCodeFormat' style={{ display: 'inline-block', marginRight: '5px' }}>
                                <AutoComplete onSearch={onSearch} onSelect={value => autoComplete(value)} options={suggestions}>
                                    <Input addonAfter={selectedCountry?.postcodeFormat} onChange={() => { return true; }} />
                                </AutoComplete>
                            </FormItem>
                            }
                            {!hasZipCodePattern() &&
                                <FormItem label={translate('applications.data.street')} name='street' required={true}>
                                    <Input />
                                </FormItem>
                                
                            }
                            {hasZipCodePattern() &&
                            <FormItem label={translate('applications.data.number').toString()} name='number' required={true} style={{ display: 'inline-block' }} extra={address}>
                                <Input onChange={e => autoComplete(undefined, e.target.value)} />
                            </FormItem>
                            }
                            {!hasZipCodePattern() &&
                            <FormItem label={translate('applications.data.number').toString()} name='number' required={true}>
                                <Input />
                            </FormItem>
                            }
                            {!hasZipCodePattern() &&
                            <FormItem label={translate('applications.data.city')} name='city' required={true}>
                                <Input />
                            </FormItem>
                            }
                            {municipalities.length > 1 &&
                            <FormItem name='municipality' required={true}>
                                <Select onChange={provider.setMunicipality}>
                                    {municipalities.map(x => <Select.Option key={x.municipalityId} value={x.municipalityId}>{x.name}</Select.Option>)}
                                </Select>
                            </FormItem>
                            }
                            {hasZipCodePattern() &&
                            <ErrorDiv style={pcOrNumberError  ? { display: 'block'} : { display: 'none'}}>
                                {translate('applications.data.zipCodeOrNumberInvalid')}
                            </ErrorDiv>
                            }
                        </div>
                ) : <></>}                
                <div>
                    <Title level={3}>{translate('applications.steps.welcome.contractTitle')}</Title>
                    <FormItem name='startAt' required={true}>
                        <DateInput onChange={provider.setStartAt}/>
                    </FormItem>
                </div>
            </Container>
    );
};

const ErrorDiv = styled.div`
    color: #ff4d4f;
    display: none;
`;

const Container = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    > div {
        flex: 1 0;
        min-width: 150px;
        border-right: 1px solid #eee;
        padding-right: 10px;
        .ant-form-item-extra {
            margin-top: 10px;
        }
    }
    >div:last-child {
        border-right: none;
    }
    
`;