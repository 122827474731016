import { ContentResponse } from '../models/content-response';
import { AllContentResponse } from '../models/all-content-response';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class ContentApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async getCode(langCode: string, code: string, headers?: AxiosHeaders): Promise<AxiosResponse<ContentResponse>> {
        const request = {
            url: this.baseUrl + '/content/{code}'
                .replace(`{${'code'}}`, encodeURIComponent(code)) + '?lang=' + langCode,
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<ContentResponse>(request);
        

        return response;
    }

    public async getAll(langCode: string, headers?: AxiosHeaders): Promise<AxiosResponse<AllContentResponse>> {
        const request = {
            url: this.baseUrl + '/content/all?lang=' + langCode,
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<AllContentResponse>(request);
        

        return response;
    }
}