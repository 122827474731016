import React from 'react';
import moment from 'moment';
import { Form, DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { FormItemProps, Rule } from 'antd/lib/form';
import { useTranslate } from '@/language-provider';

interface Props {
    name?: string;
    text?: string;
    required: boolean;
    regex?: string;
    regexMessage?: string;
    noLabel?: boolean;
    primaryEmail?: () => string;
    partnerEmail?: () => string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormItem: React.FC<Props & FormItemProps> = props => {
    const { labelText } = useFormDataLabel();
    const { translate } = useTranslate();

    const { name, text, required, children, regex, regexMessage, noLabel = false, primaryEmail, partnerEmail } = props;

    const mustNotBeEmpty = (rule, value, callback): void => {
        //console.log('rule', rule);        
        if (rule.field === 'child.emergencyNumber' || rule.field === 'primaryContact.phone' || rule.field === 'partner.phone') {
            if (!value.phone || value.phone === '') {
                callback(translate('global.generic.requiredFormat', { label: labelText(name, text) }).toString());
            }
        }

        if (rule.field === 'partner.email') {
            if (value === primaryEmail()) {
                callback(translate('applications.data.emailMustNotBeEqual', { label: labelText(name, text) }).toString());
            }
        }
        if (rule.field === 'primaryContact.email') {
            if (value === partnerEmail()) {
                callback(translate('applications.data.emailMustNotBeEqual', { label: labelText(name, text) }).toString());
            }
        }
        callback();
      };
    const rules: Rule[] = [];
    if (required) {
        rules.push({ required: required, message: translate('global.generic.requiredFormat',
            { label: labelText(name, text) }).toString() });
        rules.push({validator: mustNotBeEmpty});
    }
    if (regex != null) {
        rules.push({ pattern: new RegExp(regex, 'g'), message: translate(regexMessage).toString() });
    }

    return (
        <Form.Item {...props} name={name} label={noLabel ? undefined : labelText(name, text)} rules={rules}>
            {children}
        </Form.Item>
    );
};
export type FormLabelFunction = (name?: string, text?: string) => string;
export const useFormDataLabel = (): { labelText: FormLabelFunction } => {
    const { translate } = useTranslate();

    return {
        labelText: (name: string, text?: string) => {
            if (text != null) {
                return text;
            } else if (name == null) {
                return '';
            }

            const key = name.replace('child.', 'person.').replace('primaryContact.', 'person.').replace('partner.', 'person.');
            return translate(`applications.data.${key}`).toString();
        }
    };
};

export function DateInput(props: DatePickerProps): React.ReactElement {
    const dateFormatList = [moment.localeData().longDateFormat('L'), 'D-M-YYYY', 'D-M-YY', 'DD-MM-YY'];
    return <DatePicker {...props} format={dateFormatList} style={{ width: '100%' }} />;
}

export class FormUtilities {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static flattenObject(source: any): any {
        const destination = {};
        FormUtilities.flattenObjectInternal(source, '', destination);
        return destination;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static flattenObjectInternal(source: any, parent: string, destination: any): void {
        for (const key in source) {
            const property = parent ? parent + '.' + key : key;
            const value = source[key];
            if (typeof value === 'object' && !moment.isMoment(value)) {
                FormUtilities.flattenObjectInternal(value, property, destination);
            } else {
                destination[property] = value;
            }
        }
        return destination;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static rebuildObject(source: any): any {
        const destination = {};
        for (const key in source) {
            let property = key;
            let dest = destination;
            let index = property.indexOf('.');
            while (index >= 0) {
                const childKey = property.substring(0, index);
                property = property.substring(index + 1);
                index = property.indexOf('.');

                let child = dest[childKey];
                if (child == null) {
                    child = dest[childKey] = {};
                }
                dest = child;
            }
            dest[property] = source[key];
        }
        return destination;
    }
}