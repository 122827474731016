import { SignInModel } from '../models/sign-in-model';
import { TokenModel, onDeserializeTokenModel, onSerializeTokenModel } from '../models/token-model';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class AuthApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async postTenantSignin(tenant: string, data?: SignInModel, headers?: AxiosHeaders): Promise<AxiosResponse<TokenModel>> {
        const request = {
            url: this.baseUrl + '/auth/{tenant}/signin'
                .replace(`{${'tenant'}}`, encodeURIComponent(tenant)),
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);

        request.data = data;

        const response = await globalAxios.request<TokenModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTokenModel(response.data);
        }

        return response;
    }

    public async getRefresh(headers?: AxiosHeaders): Promise<AxiosResponse<TokenModel>> {
        const request = {
            url: this.baseUrl + '/auth/refresh',
            method: 'GET',
            responseType: 'json',
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<TokenModel>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeTokenModel(response.data);
        }

        return response;
    }
}