import { Child, ChildDetails, onDeserializeChild, onSerializeChild} from './child';
import { Package} from './package';
import { ApplicationAnswer} from './application-answer';
import { CareType } from './care-type';
import moment from 'moment';

export interface AddChildCommand {
    applicationId: string;
    startAt: moment.Moment;
    careType: CareType;
    schoolId?: string;
    child?: ChildDetails;
    preferredLocations?: string[];
    packages?: Package[];
    remarks?: string;
    answers?: ApplicationAnswer[];
}

export function onDeserializeAddChildCommand(data: AddChildCommand): AddChildCommand {
    if (data == null) {
        return data;
    }

    if (data.startAt != null) {
        data.startAt = moment(data.startAt);
    }
    if (data.child != null) {
        data.child = onDeserializeChild(data.child);
    }

    return data;
}

export function onSerializeAddChildCommand(data: AddChildCommand): AddChildCommand {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.startAt = data.startAt?.toISOString(true) as unknown as moment.Moment;
    serialized.child = onSerializeChild(data.child);

    return serialized;
}
