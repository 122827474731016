import { ApplicationData } from './application-data';
import { Validator } from './validator';
import { QuestionModel } from '@/client/models';

export class QuestionsValidator implements Validator {

    private questions: QuestionModel[];

    constructor() {
        this.validate = this.validate.bind(this);
        this.initialize = this.initialize.bind(this);
    }

    public initialize(questions: QuestionModel[]): void {
        this.questions = questions;
    }

    public validate(data: ApplicationData): boolean {
        if (this.questions == null) {
            return true;
        }

        for (const question of this.questions) {
            if (!question.isRequired) {
                continue;
            }

            if (question.packageId != null && !data.packages.some(p => p.carePackageId === question.packageId)) {
                continue;
            }

            if (!data.answers.some(a => a.questionId === question.questionId && (a.answer != null || a.answer !== ''))) {
                return false;
            }
        }

        return true;
    }
}
