import React from 'react';
import { ApplicationProviderComponent } from '@/applications/application-provider';
import { ApplicationWizard } from './application/application-wizard';
import { useTenant } from '@/tenant/tenant-provider';
import styled from 'styled-components';
import { Button, Result } from 'antd';


export const ApplicationScreen: React.FC = () => {
    const tenant = useTenant();

    if (tenant.tenant == null) {
        return (
            <Result
                status="error"
                title="Geen omgeving gespecificeerd"
                subTitle="Probeer het nog eens door terug te gaan"
                extra={[
                    <Button type="primary" key="console" onClick={() => globalThis.window.history.back()}>
                      Terug
                    </Button>
                  ]}
            />
        );
    }

    return (
        <FormContainer>
            <CompanyHeader>
                <a href={tenant.companyWebsite} target='_blank' rel="noreferrer">
                    <img src={tenant.companyPictureUrl} style={{width: 'auto', maxHeight: '128px', objectFit: 'contain'}} />
                </a>
                <div>
                    <h1>{tenant.companyName}</h1>
                </div>
            </CompanyHeader>
            <div>
                <ApplicationProviderComponent>
                    <ApplicationWizard />
                </ApplicationProviderComponent>
            </div>
        </FormContainer>
    );
};

const FormContainer = styled.div`
    margin: 5px;
`;

const CompanyHeader = styled.div`
    display: flex;
    align-items: center;
    margin: 25px;

    > div {
        flex: 1;
        > h1 {
            font-size: 30px;
            color: ${x => x.theme.primary};
            text-align: center;
            margin-bottom: 0px;
        }
    }

    > a {
        flex: 0;
    }
`;