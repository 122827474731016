import React, { useState } from 'react';
import { useStepChanged, useStepsProvider } from '@/applications/application-provider';
import { Steps, Divider, Spin } from 'antd';
import styled from 'styled-components';
import { Step, ToText } from '@/applications/steps-provider';
import { CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Button } from '@/components/button';
import { useTranslate } from '@/language-provider';
import { submitFormSelector } from '@/store/application/selectors';
import { useSelector } from 'react-redux';
import { Status } from '@/store/status';
import { FinishedScreen } from '@/screens/application/finished-screen';
const { Step } = Steps;

export const ApplicationWizard: React.FC = () => {
    const stepsProvider = useStepsProvider();
    const { status, error } = useSelector(submitFormSelector);
    const [stepIndex, setStepIndex] = useState(stepsProvider.getCurrentStepIndex());
    const [steps, setSteps] = useState(stepsProvider.getSteps());
    const { translate } = useTranslate();

    const [isNextActive, setIsNextActive] = useState(stepsProvider.isNextActive());

    const getStatus = (x: Step): 'wait' | 'process' | 'finish' | 'error' => {
        if (x.isCompleted) {
            return 'finish';
        }
        if (stepsProvider.isPreviousCompleted(x.key) && !stepsProvider.canGoToNextStep(x.key)) {
            return 'process';
        }
        return 'wait';
    };

    const getIcon = (x: Step): React.ReactNode => {
        if (x.isCompleted) {
            return <CheckOutlined />;
        }
        return undefined;
    };

    const createSteps = (steps: Step[]): JSX.Element[] => {
        return steps.filter(x => x != null).map(x => <Step key={x.key}
            status={getStatus(x)}
            icon={getIcon(x)}
            title={ToText(translate, x.title)}
            subTitle={ToText(translate, x.subTitle)}
            description={ToText(translate, x.description)}
            disabled={!stepsProvider.canGoToStep(x.key)} />);
    };

    const [wizardSteps, setWizardSteps] = useState(createSteps(stepsProvider.getSteps()));

    useStepChanged(() => {
        const newSteps = stepsProvider.getSteps();
        setStepIndex(stepsProvider.getCurrentStepIndex());
        setSteps(newSteps);
        setIsNextActive(stepsProvider.isNextActive());

        setWizardSteps(createSteps(newSteps));
    });

    if (status === Status.Loaded && error == null) {
        return <FinishedScreen />;
    }

    const currentStep = steps[stepIndex];
    if (steps.length === 0) {
        return <Spin spinning={true} tip={translate('global.generic.loading').toString()} />;
    }

    let hasAnyComment = false;
    for (const step in steps) {
        if (steps[step].content) {
            hasAnyComment = true;
            break;
        }
    }

    return (
        <WizardContainer>

            <StepsPanel>
                <Steps direction='vertical' current={stepIndex} onChange={stepsProvider.moveTo} size='small'>
                    {...wizardSteps}
                </Steps>
            </StepsPanel>

            <RightView>
                {hasAnyComment && <CommentPanel>
                    <div className='content'>
                        <div className='content-box'>
                            <div dangerouslySetInnerHTML={{ __html: currentStep?.content?.htmlContent }} />
                        </div>
                    </div>
                </CommentPanel>}
                <ContentView>
                    <Title>{ToText(translate, currentStep.title)}</Title>
                    <Divider />
                    {currentStep?.component}
                    {(stepIndex < steps.length - 1) &&
                        <Button
                            translation='global.generic.next'
                            icon={<ArrowRightOutlined />}
                            type='primary'
                            disabled={!isNextActive}
                            onClick={() => stepsProvider.next()} />}
                    {(stepIndex === steps.length - 1) &&
                        <Button
                            translation='global.button.save'
                            icon={<CheckOutlined />}
                            type='primary'
                            onClick={() => stepsProvider.done()}
                        />}

                </ContentView>
            </RightView>



        </WizardContainer>
    );
};

const WizardContainer = styled.div`
    display: flex;

    h1 {
        font-size: 21px;
        color: ${x => x.theme.primary};
    }

    h2 {
        font-size: 14px;
        color: ${x => x.theme.primary};
    }

    h3 {
        color: ${x => x.theme.primary};
    }

    label {
        color: ${x => x.theme.primary};
    }

    .ant-btn-primary {
        background-color: ${x => x.theme.primary};
        border-color: ${x => x.theme.primary};

        .anticon {
            color: ${x => x.theme.secondary};
        }
    }

    .anticon, .ant-avatar {
        color: ${x => x.theme.primary};
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: ${x => x.theme.primary};
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background-color: ${x => x.theme.primary};
        border-color: ${x => x.theme.primary};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: ${x => x.theme.primary};
        border-color: ${x => x.theme.primary} !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: ${x => x.theme.primary};
    }

    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
    .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
        color: ${x => x.theme.primary};
    }

    .ant-radio-button-wrapper:hover {
        color: ${x => x.theme.secondary};
    }

    .ant-picker.ant-picker-focused, .ant-picker:hover, .ant-input:hover {
        border-color: ${x => x.theme.primary};
    }

    .ant-switch.ant-switch-checked {
        background-color: ${x => x.theme.primary};
    }
`;

const RightView  = styled.div`
    flex: 5;
`;

const ContentView = styled.div`
    border-radius: 15px;
    border: 1px solid ${x => x.theme.primary};
    padding: 15px;
    margin-left: 15px;
`;

const StepsPanel = styled.div`
    flex: 1;
    border-radius: 15px;
    border: 1px solid ${x => x.theme.primary};
    padding: 15px;
`;

const CommentPanel = styled.div`
    flex: 1;
    border-radius: 15px;
    border: 1px solid ${x => x.theme.primary};
    padding: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
`;
