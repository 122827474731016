import { CarePackageTimeslot, onDeserializeCarePackageTimeslot, onSerializeCarePackageTimeslot} from './care-package-timeslot';
import { CareType } from './care-type';
import moment from 'moment';

export interface CarePackageDetailsResponse {
    carePackageId: string;
    name?: string;
    careType: CareType;
    numberOfWeeks: number;
    startAt?: moment.Moment;
    locationId: string;
    schoolId?: string;
    careTimeId?: string;
    timeslots: CarePackageTimeslot[];
}

export function onDeserializeCarePackageDetailsResponse(data: CarePackageDetailsResponse): CarePackageDetailsResponse {
    if (data == null) {
        return data;
    }

    if (data.startAt != null) {
        data.startAt = moment(data.startAt);
    }
    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            if (data.timeslots[i] != null) {
                data.timeslots[i] = onDeserializeCarePackageTimeslot(data.timeslots[i]);
            }
        }
    }

    return data;
}

export function onSerializeCarePackageDetailsResponse(data: CarePackageDetailsResponse): CarePackageDetailsResponse {
    if (data == null) {
        return data;
    }

    const serialized = { ...data };

    serialized.startAt = data.startAt?.format('YYYY-MM-DD') as unknown as moment.Moment;
    if (data.timeslots != null) {
        for (let i = 0; i < data.timeslots.length; i++) {
            serialized.timeslots[i] = onSerializeCarePackageTimeslot(data.timeslots[i]);
        }
    }      

    return serialized;
}
