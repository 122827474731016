import { DefaultTheme } from 'styled-components';
import { lightColor, darkColor, dataFontColor } from './colors';

const baseTheme: DefaultTheme = {
    background: '',
    inverted: '',
    primary: '#31B2F0',
    secondary: '#9FDBF8',
    tertiary: '#036DA0',
    dataFontColor: dataFontColor
};

export const LightTheme: DefaultTheme = {
    ...baseTheme,
    background: lightColor,
    inverted: darkColor
};