import { LocationsResponse } from '../models/locations-response';
import { CareType } from '../models/care-type';
import { CarePackageDetailsResponse, onDeserializeCarePackageDetailsResponse, onSerializeCarePackageDetailsResponse } from '../models/care-package-details-response';
import moment from 'moment';
import qs from 'qs';
import globalAxios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { mergeHeaders, AxiosHeaders } from '../utilities/merge-headers';

export class LocationsApi {
    private baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get(date?: moment.Moment, schoolId?: string, careType?: CareType, headers?: AxiosHeaders): Promise<AxiosResponse<LocationsResponse>> {
        const request = {
            url: this.baseUrl + '/locations',
            method: 'GET',
            responseType: 'json',
            params: {
                date: date?.format('YYYY-MM-DD'),
                schoolId: schoolId,
                careType: careType,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<LocationsResponse>(request);
        

        return response;
    }

    public async getLocationIdCarepackagesCarePackageId(locationId: string, carePackageId: string, date?: moment.Moment, schoolId?: string, headers?: AxiosHeaders): Promise<AxiosResponse<CarePackageDetailsResponse>> {
        const request = {
            url: this.baseUrl + '/locations/{locationId}/carepackages/{carePackageId}'
                .replace(`{${'locationId'}}`, encodeURIComponent(locationId))
                .replace(`{${'carePackageId'}}`, encodeURIComponent(carePackageId)),
            method: 'GET',
            responseType: 'json',
            params: {
                date: date?.format('YYYY-MM-DD'),
                schoolId: schoolId,
            },
            paramsSerializer: params => qs.stringify(params)
        } as AxiosRequestConfig;

        request.headers = mergeHeaders(request.headers, headers);


        const response = await globalAxios.request<CarePackageDetailsResponse>(request);
        
        if (response.status === 200) {
            response.data = onDeserializeCarePackageDetailsResponse(response.data);
        }

        return response;
    }
}