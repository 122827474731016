import { StepData as WelcomeStepData } from './welcome-step-provider';
import { StepData as CareTypeStepData } from './care-type-step-provider';
import { StepData as SchoolStepData } from './school-step-provider';
import { StepData as LocationStepData } from './location-step-provider';
import { StepData as CarePackageStepData } from './care-package-step-provider';
import { StepData as YourDataStepData } from './your-data-step-provider';
import { StepData as QuestionsStepData } from './questions-step-provider';

export enum Mode {
    new = 'new',
    add = 'add'
}

export interface ApplicationData extends
    WelcomeStepData,
    CareTypeStepData,
    SchoolStepData,
    LocationStepData,
    CarePackageStepData,
    YourDataStepData,
    QuestionsStepData {

        applicationId: string;
        existingApplicationId: string;
        mode: Mode;
        remarks?: string;
}
