import React, { useContext } from 'react';
import { NamedLanguage as ReactNamedLanguage, LocalizeContext, LocalizeContextProps } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import moment from 'moment';

import 'moment/locale/nl';
import 'moment/locale/en-gb';

import enTranslations from '@/translations/en.json';
import nlTranslations from '@/translations/nl.json';

import { ConfigProvider } from 'antd';

import enUS from 'antd/es/locale/en_US';
import nlNL from 'antd/es/locale/nl_NL';
import { Locale } from 'antd/lib/locale-provider';
import qs from 'qs';

type NamedLanguage = ReactNamedLanguage & { antLocale: Locale };

const parsed = qs.parse(globalThis.window.location.search.slice(1));
const DefaultLanguage = parsed['lang']?.toString() ?? 'nl';
const DefaultCountry = parsed['country']?.toString() ?? 'NL';

export class Country {
    code: string;
}
export const country: Country = {code: DefaultCountry};

export const languages: NamedLanguage[] = [{
    name: 'Nederlands',
    code: 'nl',
    antLocale: nlNL
}, {
    name: 'English',
    code: 'en',
    antLocale: enUS
}];

export function useTranslate(): LocalizeContextProps {
    return useContext(LocalizeContext);
}

export function useActiveLanguage(): [NamedLanguage, (language: NamedLanguage) => void] {
    const languageContext = useTranslate();

    const code = languageContext.activeLanguage?.code ?? DefaultLanguage;
    const activeLanguage = languages.find(l => l.code === code);

    return [
        activeLanguage,
        (language) => {
            moment.locale(language.code);
            languageContext.setActiveLanguage(language.code);
        }
    ];
}

const onMissingTranslation = ({ translationId, languageCode }): string => {
    return `Nada for ${translationId} - ${languageCode}`;
  };


export const LanguageProvider: React.FC = ({ children }) => {
    const languageContext = useTranslate();
    const [activeLanguage, setActiveLanguage] = useActiveLanguage();

    if (languageContext.languages.length === 0) {
        languageContext.initialize({
            languages: languages,
            options: { renderToStaticMarkup, onMissingTranslation }
        });

        languageContext.addTranslationForLanguage(enTranslations, 'en');
        languageContext.addTranslationForLanguage(nlTranslations, 'nl');
        languageContext.defaultLanguage = DefaultLanguage;

        setActiveLanguage(languages.find(l => l.code === DefaultLanguage));
    }

    return (
        <ConfigProvider locale={activeLanguage.antLocale}>
            {children}
        </ConfigProvider>
    );
};