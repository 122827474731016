import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import Title from 'antd/lib/typography/Title';
import { Form, Input, InputNumber, Select } from 'antd';
import { useQuestionsStepProvider, useApplicationChanged, useOnNext } from '@/applications/application-provider';
import { FormItem } from '../components/form-item';
import { QuestionModel, QuestionType } from '@/client/models';
import { AnswerData } from '@/applications/questions-step-provider';
import { ToText } from '@/applications/steps-provider';
import { useTranslate } from '@/language-provider';
import styled from 'styled-components';
import TextArea from 'rc-textarea';

export const QuestionsStep: React.FC = () => {
    const provider = useQuestionsStepProvider();
    const [form] = useForm();
    const { translate } = useTranslate();
    useOnNext(() => form.submit());

    useApplicationChanged(() => {
        form.setFieldsValue(createFormData(provider.getAnswers()));
    });

    const onFinish = (): void => {
        provider.setAnswers(getAnswers(form.getFieldsValue()));
        provider.next();
    };

    const onChange = (): void => {
        provider.setAnswers(getAnswers(form.getFieldsValue()));
    };

    const getAnswers = (formData: { [key: string]: unknown }): AnswerData[] => {
        const answers = new Array<AnswerData>();
        for (const questionId in formData) {
            answers.push({ questionId, answer: formData[questionId]?.toString() });
        }
        return answers;
    };

    const createFormData = (answers: AnswerData[]): unknown => {
        const formData = {};
        for (const answer of answers) {
            formData[answer.questionId] = answer.answer;
        }
        return formData;
    };

    const questionGroups = provider.getQuestionGroups();
    return (
        <Container form={form} layout='vertical' onFinish={onFinish} initialValues={createFormData(provider.getAnswers())} onChange={onChange} style={{ display: 'flex' }}>
            {questionGroups.map(g =>
                <div key={g.carePackageId ?? 'default'}>
                    <Title level={3}>{ToText(translate, g.title)}</Title>
                    {g.questions.map(q => (
                        <FormItem key={q.questionId} text={q.question} name={q.questionId} required={q.isRequired}>
                            <QuestionInput question={q} />
                        </FormItem>
                    ))}
                </div>
            )}
        </Container>
    );
};

const Container = styled(Form)`
    display: flex;
    flex-direction: column;
    
    > div {
        flex: 1;
    }
`;

const QuestionInput: React.FC<{ value?: string; onChange?: (value?: string) => void; question: QuestionModel }> = ({ value, onChange, question }) => {
    if (question.questionType === QuestionType.Amount) {
        let parsed = parseInt(value);
        parsed = isNaN(parsed) ? 0 : parsed;
        return (<InputNumber value={parsed} onChange={v => onChange(v?.toString())} min={0} style={{ width: '100%' }} />);
    } else if (question.questionType === QuestionType.Multiplechoice) {
        return (<Select value={value} onChange={onChange}>{question?.answers.map(a => <Select.Option key={a.answerId} value={a.answer}>{a.answer}</Select.Option>)}</Select>);
    } else if (question.questionType === QuestionType.MultilineText) {
        return (<TextArea value={value} onChange={e => onChange(e.target?.value)} rows={5} style={{ width: '100%', resize: 'none', border: '1px solid #d9d9d9', outline: 'none' }}/>);
    }
    return (<Input value={value} onChange={e => onChange(e.target?.value)} />);
};